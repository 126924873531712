import React from "react";
import { Card, Image, Text, makeStyles } from "@fluentui/react-components";
import TitleText from "../TitleText";

interface CardItem {
  title: string;
  description: string;
  imageAlt: string;
}

interface FeatureSectionProps {
  title: string;
  subtitle: string;
  cards: CardItem[];
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: "0 2rem",
    maxWidth: "1200px",
    margin: "0 auto",
    marginTop: "7rem",
    justifyContent: "center",
    alignItems: "center",
    '@media (max-width: 768px)': {
      flexDirection: "column",
      marginTop: "1rem",
    },
  },
  fullCardContent: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    '@media (max-width: 768px)': {
      flexDirection: "column",
    },
  },
  cardsSection: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    width: "60%",
    '@media (max-width: 768px)': {
      width: "100%",
    },
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  halfCard: {
    flex: "1 1 calc(50% - 0.5rem)",
    '@media (max-width: 768px)': {
      flex: "1 1 100%",
    },
  },
  fullCard: {
    flex: "1 1 100%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    height: "100%",
    padding: "1rem",
  },
  imageWrapper: {
    width: "100%",
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "center",
  },
  textWrapper: {
    width: "100%",
  },
  image: {
    width: "100%",
    height: "auto",
    '@media (max-width: 768px)': {
      width: "80%",
    },
  },
});

const ServiceCards: React.FC<FeatureSectionProps> = ({
  title,
  subtitle,
  cards,
}) => {
  const classes = useStyles();

  // Hardcoded image URLs
  const imageUrls = [
    "https://www.consultdolphin.com/assets/img/illustrations/illustration-8.png",
    "https://www.consultdolphin.com/assets/img/illustrations/illustration-2.png",
    "https://www.consultdolphin.com/assets/img/illustrations/illustration-7.png",
  ];

  return (
    <div className={classes.container}>
      {/* Text Section */}
      <TitleText title={title} subtitle={subtitle} />

      {/* Cards Section */}
      <div className={classes.cardsSection}>
        {/* First Two Cards */}
        {cards.slice(0, 2).map((card, index) => (
          <div key={index} className={`${classes.cardWrapper} ${classes.halfCard}`}>
            <Card className={classes.card}>
              <div className={classes.imageWrapper}>
                <Image
                  src={imageUrls[index]}
                  alt={card.imageAlt}
                  className={classes.image}
                />
              </div>
              <div className={classes.textWrapper}>
                <Text as="h3" block size={500}>{card.title}</Text>
                <Text block>{card.description}</Text>
              </div>
            </Card>
          </div>
        ))}
        {/* Full Width Card */}
        {cards[2] && (
          <div className={`${classes.cardWrapper} ${classes.fullCard}`}>
            <Card className={classes.card}>
              <div className={classes.fullCardContent}>
                <div className={classes.imageWrapper}>
                  <Image
                    src={imageUrls[2]}
                    alt={cards[2].imageAlt}
                    className={classes.image}
                  />
                </div>
                <div className={classes.textWrapper}>
                  <Text as="h3" block size={500}>{cards[2].title}</Text>
                  <Text block>{cards[2].description}</Text>
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceCards;