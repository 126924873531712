import ServicePageLayout from "../ServicePageLayout";

export default function PowerBI() {

    const banner = {
        title: "Microsoft Power BI",
        subtitle: "Visualise your data to make informed, insightful decisions.",
        description: "Interested to hear more?",
        buttonText: "Get In Touch",
        onButtonClick: () => {
            console.log("CTA Button Clicked! Redirect to contact page.");
        },
        imageSrc: "https://www.consultdolphin.com/images/pages/bi/banner.webp",
        imageAlt: "Microsoft Power BI"
    };

    const features = [
        {
            title: "Visualisation and Reporting.",
            description:
                "Extract the data you need, transform it however you want, and then share it across your business using dynamic interactive reporting.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-8.png",
            imageAlt: "Visualisation and Reporting",
            imagePosition: "left",
        },
        {
            title: "Accessibility.",
            description:
                "Access your reports anywhere and anytime through the PowerBI service.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-6.png",
            imageAlt: "Accessibility",
            imagePosition: "right",
        },
        {
            title: "Scalability.",
            description:
                "Understand your business through the analysis of data. Whether you are a multinational company with a fully implemented Data Warehouse or a small company simply using excel as a data source – build a data platform that enables you to ask questions and make informed decisions.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-2.png",
            imageAlt: "Scalability",
            imagePosition: "left",
            link: "/services/document-management",
            layout: "full",
        },
    ];

    const imageText = {
        heading: "We have lots of experience building Power BI dashboards.",
        points: [
            {
                heading: "Create",
                text: "Build reports with vibrant visual charts allowing you to see the trends in your data.",
            },
            {
                heading: "Collaborate",
                text: "Interactively discuss the output of your reports through teams channels.",
            },
            {
                heading: "Present",
                text: "Embed reports in PowerPoint and present numbers in real time.",
            },
        ],
        imageSrc: "https://www.consultdolphin.com/images/pages/bi/powerbi-desktop.png",
        imageAlt: "Power BI",
    };

    return (
        <ServicePageLayout
            banner={banner}
            featuresAlt2={{ title: "Make the most of your Azure subscription.", subtitle: "Build, run, and manage business applications.", cards: features }}
            imageText2={imageText}
        />
    );
}