import IBlob from "../models/IBlob";
import networkService from "./NetworkService";
class ImageService {

    /**
     * @description Uploads an image
     * @param {string} blob The image upload request data
     */
    async getUploadUrl(blob: IBlob): Promise<string> {
        const result = await networkService.post<IBlob, string>(`/images/url`, blob);

        if (result.isSuccessful) {
            return result.data as string;
        }

        throw result.data;
    }

    async downloadFile(url: string): Promise<File> {
        const response = await fetch(url);
 
        if (!response.ok) {
            throw new Error();
        }
 
        const filename = url.split('/').pop()!;
 
        const blob = await response.blob();
        const file = new File([blob], filename, { type: blob.type });
 
        return file;
    }
 
    /**
     * @description Upload a file to the specified URL
     * @param {string} url The URL to upload the file to
     * @param {string} fileBase64 The base64 content of the file to be uploaded
     */
    async uploadFile(url: string, fileBase64: string): Promise<boolean> {
        // Decode Base64 string to binary data
        const byteCharacters = atob(fileBase64);
        const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
 
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                "x-ms-blob-type": "BlockBlob",
            },
            body: byteArray,
        });

        if (!response.ok) {
            throw new Error();
        }
 
        return response.ok;
    }
}

const imageService = new ImageService();
export default imageService;