import ITestimonial from "../models/ITestimonial";
import networkService from "./NetworkService";

class TestimonialService {

    /**
     * @description Gets all testimonials
     */
    async getTestimonials(): Promise<ITestimonial[]> {
        const result = await networkService.get<ITestimonial[]>(`/testimonials`);

        if (result.isSuccessful) {
            return result.data as ITestimonial[];
        }

        throw result.data;
    }

    /**
     * @description Gets the specified testimonial
     * @param {string} id The ID of the testimonial to be returned
     */
    async getTestimonial(id: string): Promise<ITestimonial> {
        const result = await networkService.get<ITestimonial>(`/testimonial/${id}`);

        if (result.isSuccessful) {
            return result.data as ITestimonial;
        }

        throw result.data;
    }

    /**
     * @description Creates a new testimonial
     * @param {ITestimonial} testimonial The testimonial data to be created
     */
    async createTestimonial(testimonial: ITestimonial): Promise<ITestimonial> {
        const result = await networkService.put<ITestimonial, ITestimonial>(`/testimonial`, testimonial);

        if (result.isSuccessful) {
            return result.data as ITestimonial;
        }

        throw result.data;
    }

    /**
     * @description Updates the specified testimonial
     * @param {string} id The ID of the testimonial to be updated
     * @param {Partial<ITestimonial>} testimonial The partial testimonial data to be updated
     */
    async updateTestimonial(id: string, testimonial: Partial<ITestimonial>): Promise<ITestimonial> {
        const result = await networkService.patch<Partial<ITestimonial>, ITestimonial>(`/testimonial/${id}`, testimonial);

        if (result.isSuccessful) {
            return result.data as ITestimonial;
        }

        throw result.data;
    }

    /**
     * @description Deletes the specified testimonial
     * @param {string} id The ID of the testimonial to be deleted
     */
    async deleteTestimonial(id: string): Promise<void> {
        const result = await networkService.delete<void>(`/testimonial/${id}`);

        if (result.isSuccessful) {
            return;
        }

        throw result.data;
    }
}

const testimonialService = new TestimonialService();
export default testimonialService;