import React, { useEffect, useState } from "react";
import { Image, Text, makeStyles, Button, Card } from "@fluentui/react-components";
import IPartner from "../../../models/IPartner";
import partnerService from "../../../services/PartnerService";

const useStyles = makeStyles({
    mainContainer: {
        padding: 0,
    },
    headerImageContainer: {
        position: "relative",
        width: "100%",
        height: "600px",
    },
    headerImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    headerTextOverlay: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "white",
        textAlign: "center",
    },
    partnersContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        padding: "32px",
    },
    partnerCard: {
        flex: "1 1 calc(50% - 16px)", // Two per row
        maxWidth: "calc(50% - 16px)",
        background: "white",
        borderRadius: "8px",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    },
    fullWidthCard: {
        flex: "1 1 100%", // Full width
        maxWidth: "100%",
    },
    dimmedCard: {
        backgroundColor: "#0F294E",
        color: "white",
    },
    partnerImage: {
        width: "100%",
        height: "auto",
    },
    partnerCardContent: {
        padding: "16px",
    },
    partnerDescription: {
        margin: "8px 0",
    },
    visitButton: {
        marginTop: "auto",
    },
});

const Partners: React.FC = () => {
    const styles = useStyles();
    const [partners, setPartners] = useState<IPartner[]>([]);

    useEffect(() => {
        const fetchPartners = async () => {
            try {
                const data = await partnerService.getPartners();
                setPartners(data);
            } catch (error) {
                console.error("Failed to fetch partners", error);
            }
        };

        fetchPartners();
    }, []);

    return (
        <div className={styles.mainContainer}>
            {/* Header Section */}
            <div className={styles.headerImageContainer}>
                <Image
                    src="https://www.consultdolphin.com/images/pages/partners/banner.jpg"
                    alt="Header Image"
                    className={styles.headerImage}
                />
                <div className={styles.headerTextOverlay}>
                    <Text as="h1" size={900} align="center" block>
                        Our Partners
                    </Text>
                    <Text as="h3" size={600} align="center" block>
                        We've partnered with some of the biggest names in the industry to provide you with the best user experience.
                    </Text>
                </div>
            </div>

            {/* Partners Section */}
            <div className={styles.partnersContainer}>
                {partners.map((partner, index) => (
                    <Card
                        key={partner.name}
                        className={`${styles.partnerCard} ${
                            index < 2 ? styles.fullWidthCard : ""
                        } ${index % 2 === 1 ? styles.dimmedCard : ""}`}
                    >
                        <Image src={partner.logo} alt={`${partner.name} logo`} className={styles.partnerImage} />
                        <div className={styles.partnerCardContent}>
                            <Text weight="bold">{partner.name}</Text>
                            <Text className={styles.partnerDescription}>{partner.description}</Text>
                            <Button
                                as="a"
                                href={partner.website}
                                target="_blank"
                                appearance="primary"
                                className={styles.visitButton}
                            >
                                Visit {partner.name} Site
                            </Button>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default Partners;
