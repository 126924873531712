const fileToBase64 = (file: File, removePrefix: boolean = false): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let base64Data = reader.result as string;
 
            if (removePrefix) {
                base64Data = base64Data.split(",")[1];
            }
 
            resolve(base64Data);
        };
        reader.onerror = error => reject(error);
    });
}
 
export { fileToBase64 };