import React from "react";
import { Card, Button, Image, Text, makeStyles } from "@fluentui/react-components";

interface ServiceBannerProps {
  title: string; // The main heading
  subtitle: string; // The subtitle or description
  description: string; // Additional supporting text
  buttonText: string; // CTA button text
  onButtonClick: () => void; // CTA button action
  imageSrc: string; // Image URL
  imageAlt: string; // Alternative text for the image
}

const useStyles = makeStyles({
  container: {
    position: "relative", // Ensure children can use absolute positioning
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "80vh",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "25%",
    height: "100%",
    backgroundColor: "#c0dde5", // Background color
    zIndex: 1,
    '@media (max-width: 768px)': {
      width: 0,
      padding: 0
    },
  },
  imageContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "75%",
    height: "100%",
    zIndex: 2, // Ensure the image is above the background
    overflow: "hidden",
    '@media (max-width: 768px)': {
      width: "100%",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  card: {
    position: "relative", // Position it within the container
    zIndex: 10, // Ensure the card is on top of the image and background
    padding: "2rem",
    borderRadius: "15px",
    backgroundColor: "#2C5671",
    color: "white",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center", // Center align the text in the card
    justifyContent: "center",
    top: "15%", // Position the card in the center
    right: "20%",
    height: "80%",
    width: "500px",
    '@media (max-width: 768px)': {
      padding: "2rem",
      top: "initial",
      right: "initial",
      opacity: 0.9,
      height: "75%",
    },
  },
  textShadow: {
    textShadow: "2px 4px 3px rgba(0, 0, 0, 0.3)",
  },
  button: {
    marginTop: "1rem",
    backgroundColor: "white",
    color: "#2C5671",
    padding: "0.75rem 1.5rem",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "transform 0.2s",
    '&:hover': {
      transform: "translateY(-3px)",
    },
  },
});

const ServiceBanner: React.FC<ServiceBannerProps> = ({
  title,
  subtitle,
  description,
  buttonText,
  onButtonClick,
  imageSrc,
  imageAlt,
}) => {
  const styles = useStyles();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.background}></div>
        <div className={styles.imageContainer}>
          <Image src={imageSrc} alt={imageAlt} className={styles.image} />
        </div>
        <Card className={styles.card}>
          <Text as="h1" size={1000} block className={styles.textShadow}>
            {title}
          </Text>
          <Text as="h4" size={600} block className={styles.textShadow}>
            {subtitle}
          </Text>
          <Text as="p" size={400} block className={styles.textShadow}>
            {description}
          </Text>
          <Button className={styles.button} onClick={onButtonClick}>
            {buttonText}
          </Button>
        </Card>

      </div>
    </>
  );
};

export default ServiceBanner;
