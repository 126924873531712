import ServicePageLayout from "../ServicePageLayout";

export default function AzureHosting() {

    const banner = {
        title: "Microsoft Azure Support",
        subtitle: "Our team of qualified Azure consultants is dedicated to providing expert assistance to companies like yours.",
        description: "Interested to hear more?",
        buttonText: "Get In Touch",
        onButtonClick: () => {
            console.log("CTA Button Clicked! Redirect to contact page.");
        },
        imageSrc: "https://www.consultdolphin.com/images/pages/azure/azure-support.jpg",
        imageAlt: "Microsoft Azure"
    };

    const features = [
        {
            title: "Expertise You Can Trust",
            description:
                "We understand the intricate details of Azure's vast service offerings, and we're committed to staying up-to-date with the latest developments.",
        },
        {
            title: "Tailored Solutions",
            description:
                "Our consultants and support partners work closely with your team to identify your specific challenges and opportunities.",
        },
        {
            title: "Maximizing Azure Benefits",
            description:
                "We'll help you optimize your Azure resources, reduce costs, and enhance efficiency.",
        }
    ];

    const imageText = {
        heading: "How we can help",
        description1: "We understand that cost-effectiveness and scalability are crucial in today's dynamic business environment. Our consultants will work with you to identify opportunities for cost savings within your Azure infrastructure. We'll assess your security posture, address vulnerabilities, and establish a comprehensive security strategy to protect your assets and maintain compliance with industry regulations.",
        description2: "Azure, like any technology, requires ongoing maintenance and support. Our team is here to provide continuous support and monitoring, ensuring that your Azure environment remains secure, up-to-date, and performing at its best. We proactively identify and resolve issues, so you can focus on your core business activities with confidence.",
        buttonText: "Get Started with Azure",
        buttonLink: "/services/contact",
        imageSrc: "https://www.consultdolphin.com/images/pages/azure/image-1.webp",
        imageAlt: "Azure Support"
    };

    return (
        <ServicePageLayout
            banner={banner}
            featuresAlt2={{ title: "Our Commitment to Azure Excellence", subtitle: "We understand the critical role Azure plays in your operations, and we're here to help you harness its full potential.", cards: features }}
            imageText={imageText}
            includePortfolio={false}
        />
    );
}