import { makeStyles, Text, Button, Link, Image } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center', // Center the footer content
    backgroundColor: '#003D4C',
    color: '#fff',
    width: '100%', // Stretch the container fully
    height: 'auto', // Allow height to adjust based on content
    padding: '20px 0', // Add padding for better spacing
  },
  footer: {
    maxWidth: "1500px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    width: '100%', // Ensure the footer takes full width within the container
    flexDirection: 'column', // Stack items vertically by default
    '@media (min-width: 600px)': { // Apply flex direction row for larger screens
      flexDirection: 'row',
    },
  },
  column: {
    flex: 1,
    padding: '20px 0', // Add padding for better spacing
    textAlign: 'center', // Center text for better mobile view
    '@media (min-width: 600px)': { // Apply padding for larger screens
      padding: '0 20px',
      textAlign: 'left', // Align text to left for larger screens
    },
  },
  logo: {
    display: "flex",
    height: "60px",
    margin: '0 auto', // Center logo for mobile view
    '@media (min-width: 600px)': { // Remove margin for larger screens
      margin: '0',
    },
  },
  socialIcons: {
    display: 'flex',
    gap: '10px',
    marginTop: '10px',
    justifyContent: 'center', // Center social icons for mobile view
    '@media (min-width: 600px)': { // Align social icons to start for larger screens
      justifyContent: 'flex-start',
    },
  },
  privacyLink: {
    color: 'white',
    lineHeight: '1.5',
    textDecoration: 'none',
    display: 'block',
    '&:hover': {
      opacity: 1,
      color: "white",
      textDecoration: 'underline',
    }
  },
});

const Footer = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {/* First Column - Logo and Address */}
      <div className={styles.footer}>
        <div className={styles.column}>
          <Image src="https://www.consultdolphin.com/images/logos/dolphin-solutions_logo_blue-black.png" alt="Dolphin IT Solutions Logo" className={styles.logo} />
          <Text block>Austen House, Station View</Text>
          <Text block>Guildford, Surrey, GU1 4AR (UK)</Text>
        </div>

        {/* Second Column - Contact Information */}
        <div className={styles.column}>
          <Text block>CONTACT US</Text>
          <Text block>Phone: +44 (0) 203 695 2848</Text>
          <Text block>Email: enquiries@consultdolphin.com</Text>

          <div className={styles.socialIcons}>
            <Button />
            <Button />
            <Button />
          </div>
        </div>

        {/* Third Column - Privacy Links */}
        <div className={styles.column}>
          <Text block>Privacy</Text>
          <Link href="/terms" className={styles.privacyLink}>Terms of Use</Link>
          <Link href="/privacy" className={styles.privacyLink}>Privacy Policy</Link>
          <Link href="/cookie-policy" className={styles.privacyLink}>Cookie Policy</Link>
          <Link href="/recruitment-privacy" className={styles.privacyLink}>Recruitment Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;