import ServicePageLayout from "../ServicePageLayout";

export default function AzureHosting() {

    const banner = {
        title: "Cloud Hosting Solutions",
        subtitle: "Become more sustainable and contribute to a low-carbon future.",
        description: "Interested to hear more?",
        buttonText: "Get In Touch",
        onButtonClick: () => {
            console.log("CTA Button Clicked! Redirect to contact page.");
        },
        imageSrc: "https://www.consultdolphin.com/images/pages/azure/cloudhosting/image-1.webp",
        imageAlt: "Cloud Hosting Solutions"
    };

    const features = [
        {
            title: "Sustainable",
            description:
                "Migrate business workloads from on-premise data centres to the cloud and become more carbon and energy efficient.",
        },
        {
            title: "Disaster Recovery",
            description:
                "With Azure Hosting, your data is automatically backed up to a secondary location, and in the event of a disaster, the data is available from the failover location.",
        },
        {
            title: "Integrates Well",
            description:
                "As a Microsoft product, Azure integrates well with other Microsoft tools such as Microsoft 365, Outlook, SharePoint, Power BI and many more services.",
        }
    ];

    const imageText = {
        heading: "Discover all possibilities in Azure.",
        points: [
            {
                heading: "SharePoint",
                text: "A bespoke SharePoint Intranet hosted on SharePoint Server inside an Azure Virtual Machine. Azure is perfect for developing, testing, and hosting your SharePoint environment.",
            },
            {
                heading: "Data Warehousing",
                text: "Azure Cloud Data Warehousing migration & hosting for your business, that feeds directly into your business intelligence reports.",
            },
            {
                heading: "Custom Software",
                text: "Bespoke .NET application that runs in the cloud. From serverless code to full-scale web applications, the opportunities are endless.",
            },
        ],
        buttonText: "Learn More",
        buttonLink: "/services/contact",
        imageSrc: "https://www.consultdolphin.com/images/pages/azure/banner.webp",
        imageAlt: "Azure Solutions",
    };

    return (
        <ServicePageLayout
            banner={banner}
            featuresAlt2={{ title: "Make the most of your Azure subscription.", subtitle: "Build, run, and manage business applications.", cards: features }}
            imageText2={imageText}
        />
    );
}