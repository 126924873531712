import { Button, Input, Textarea, Image, Text, Card, makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column', // Make sure texts are on separate lines
    alignItems: 'center', // Center text horizontally
    marginBottom: '20px', // Reduce space after first text, make it minimal
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '@media(min-width: 768px)': {
      flexDirection: 'row',
    },
    margin: '15px 0',
  },
  demoIllustration: {
    width: '100%',
    maxWidth: '300px',
    marginBottom: '20px',
    '@media(min-width: 768px)': {
      marginBottom: '0',
      marginRight: '20px',
    },
  },
  formCard: {
    width: '100%',
    maxWidth: '600px',
    padding: '20px',
    borderRadius: '8px', // Add border radius
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add box shadow
    backgroundColor: '#f8fafc',
  },
  formInput: {
    width: '100%',
    padding: '12px',
    border: '1px solid #E5E7EB',
    borderRadius: '6px',
    background: 'white',
    fontSize: '14px',
    color: '#374151',
    transition: 'all 0.2s ease',
    margin: '0.5rem 0',
  },
  contactButton: {
    width: '100%',
    marginTop: '10px',
    borderRadius: '4px', // Add border radius to button
  },
});

export default function ContactForm() {
  const styles = useStyles();

  return (
    <div className={styles.mainContainer}>
      <div className={styles.row}>
        <Text as="h2" size={800} align="center" block style={{ marginBottom: '5px' }}>
          Schedule a demo with us
        </Text>
        <Text as="h4" size={500} style={{ color: "#869ab8", marginTop: '0' }} align="center" block>
          We can help you work smarter
        </Text>
      </div>

      <div className={styles.contentContainer}>
        <Image
          src="https://www.consultdolphin.com/assets/img/illustrations/illustration-1.png"
          alt="Contact us"
          className={styles.demoIllustration}
        />

        <Card className={styles.formCard}>
          <form>
            <Input
              placeholder="Enter your name"
              className={styles.formInput}
            />
            <Input
              placeholder="Enter your phone number"
              className={styles.formInput}
            />
            <Input
              placeholder="Enter your email"
              className={styles.formInput}
            />
            <Textarea
              placeholder="Enter your message"
              className={styles.formInput}
              rows={4}
            ></Textarea>
            <Button type="submit" appearance="primary" className={styles.contactButton}>
              CONTACT US
            </Button>
          </form>
        </Card>
      </div>
    </div>
  );
}
