import React, { useEffect, useState } from "react";
import { Card, Divider, Image, Text, makeStyles, Button, Link } from "@fluentui/react-components";
import ICaseStudy from "../../../models/ICaseStudy";
import portfolioService from "../../../services/PortfolioService";

const useStyles = makeStyles({
    mainContainer: {
        padding: 0,
    },
    headerImageContainer: {
        position: "relative",
        width: "100%",
        height: "600px",
    },
    headerImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    headerTextOverlay: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "white",
        textAlign: "center",
    },
    tagsContainer: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: "0.5rem",
        margin: "1rem auto",
    },
    tagButton: {
        padding: "0.5rem 1rem",
        cursor: "pointer",
        border: "1px solid #ccc",
        borderRadius: "20px",
        background: "#f5f5f5",
        "&:hover": {
            background: "#e0e0e0",
        },
        "&.active": {
            background: "#0078d4",
            color: "white",
        },
    },
    cardsContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
        maxWidth: "1200px",
        margin: "0 auto",
    },
    cardContainer: {
        flex: "1 1 calc(50% - 2rem)",
        margin: "2rem",
        padding: "1rem",
        maxWidth: "calc(50% - 2rem)",
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
    },
    cardImageContainer: {
        width: "100%",
        height: "200px",
    },
    cardImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    cardTextContainer: {
        marginTop: "1rem",
        textAlign: "start",
    },
});

const CaseStudies: React.FC = () => {
    const styles = useStyles();
    const [caseStudies, setCaseStudies] = useState<ICaseStudy[]>([]);
    const [filteredCaseStudies, setFilteredCaseStudies] = useState<ICaseStudy[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [selectedTag, setSelectedTag] = useState<string>("");

    useEffect(() => {
        const fetchCaseStudies = async () => {
            try {
                const data = await portfolioService.getCaseStudies();
                setCaseStudies(data);
                setFilteredCaseStudies(data);

                // Extract unique tags
                const uniqueTags = Array.from(
                    new Set(data.flatMap((caseStudy) => caseStudy.tags))
                );
                setTags(uniqueTags);
            } catch (error) {
                console.error("Failed to fetch case studies", error);
            }
        };

        fetchCaseStudies();
    }, []);

    const handleTagClick = (tag: string) => {
        setSelectedTag(tag);
        setFilteredCaseStudies(
            caseStudies.filter((caseStudy) => caseStudy.tags.includes(tag))
        );
    };

    const handleClearFilter = () => {
        setSelectedTag("");
        setFilteredCaseStudies(caseStudies);
    };

    return (
        <div className={styles.mainContainer}>
            {/* Header Section */}
            <div className={styles.headerImageContainer}>
                <Image
                    src="https://www.consultdolphin.com/assets/img/covers/cover-3.jpg"
                    alt="Header Image"
                    className={styles.headerImage}
                />
                <div className={styles.headerTextOverlay}>
                    <Text as="h1" size={900} align="center" block>
                        Our Work.
                    </Text>
                    <Text as="h3" size={600} align="center" block>
                        We have worked with a number of clients within almost any industry. Read our case studies to find out more about our achievements and client success stories.
                    </Text>

                    <div className={styles.tagsContainer}>
                        {tags.map((tag) => (
                            <Button
                                key={tag}
                                className={`${styles.tagButton} ${selectedTag === tag ? "active" : ""
                                    }`}
                                onClick={() => handleTagClick(tag)}
                            >
                                {tag}
                            </Button>
                        ))}
                        {selectedTag && (
                            <Button className={styles.tagButton} onClick={handleClearFilter}>
                                Clear Filter
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            <div className={styles.cardsContainer}>
                {filteredCaseStudies.map((card, index) => (
                    <Card className={styles.cardContainer} key={index}>
                        <Link as="a" href={`/casestudy/${card.id}`}>
                        <div className={styles.cardContent}>
                            <div className={styles.cardImageContainer}>
                                <Image
                                    src={card.image.url}
                                    alt={card.image.altText}
                                    className={styles.cardImage}
                                />
                            </div>
                            <div className={styles.cardTextContainer}>
                                <Text as="h4" size={500} block align="center">
                                    {card.client.name}
                                </Text>
                                <Text as="p" size={400} block style={{ color: "#869ab8" }}>
                                    {card.title}
                                </Text>

                                <Divider />
                                <Text as="p" size={400} block style={{ color: "#869ab8", textTransform: "uppercase" }}>
                                    {card.client.industry}
                                </Text>
                            </div>
                        </div>
                        </Link>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default CaseStudies;
