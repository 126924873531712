import React from "react";
import { Card, CardHeader, Text, Title3 } from "@fluentui/react-components";
import { Col, Container, Row } from "react-grid-system";
import styles from "./ServiceCards3.module.scss";

interface CardItem {
  title: string;
  description: string;
  link?: string;
}

interface FeatureSectionProps {
  title: string;
  subtitle: string;
  cards: CardItem[];
}

const ServiceCards3: React.FC<FeatureSectionProps> = ({
  title,
  subtitle,
  cards,
}) => {
  return (
    <Container fluid className={styles.mainContainer}>
      {/* Header Section */}
      <Row className={styles.row} justify="center">
        <Col md={12}>
          <Text as="h2" size={800} align="center" block>
            {title}
          </Text>
          <Text as="h4" size={500} style={{ color: "#869ab8" }} align="center" block>
            {subtitle}
          </Text>
        </Col>
      </Row>

      <Row className={styles.row}>
        {cards.map((card, index) => (
          <Col xs={12} md={4} className={styles.cardCol} key={index}>
            <Card className={styles.card}>
              <CardHeader
                header={
                  <Title3 style={{ padding: '20px 20px 0 20px' }}>
                    {card.title}
                  </Title3>
                }
                description={
                  <Text as="p" size={400} style={{ padding: '10px 20px', color: "#869ab8" }}>
                    {card.description}
                  </Text>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ServiceCards3;