// // File: src/app/blog/Blog.tsx
// import React, { useState, useEffect } from 'react';
// import {
//   Title1,
//   Title2,
//   Body1,
//   Card,
//   CardHeader,
//   CardPreview,
//   TabList,
//   Tab,
// } from "@fluentui/react-components";
// import blogService from '../services/BlogService';
// import IBlogPost from '../models/IBlogPost';
// import styles from './Blog.module.scss';

// const Blog = () => {
//   const [blogs, setBlogs] = useState<IBlogPost[]>([]);
//   const [activeCategory, setActiveCategory] = useState('all');
//   const [searchTerm, setSearchTerm] = useState('');

//   useEffect(() => {
//     const fetchBlogs = async () => {
//       try {
//         console.log('Fetching blogs...');
//         const data = await blogService.getBlogs();
//         console.log('API Response:', data);
//         setBlogs(data);
//       } catch (error) {
//         console.error('Error fetching blogs:', error);
//         // If you need to log specific error details, type check first
//         if (error instanceof Error) {
//           console.error('Error details:', {
//             message: error.message,
//             // Only log additional properties if they exist
//             ...(error as any).status && { status: (error as any).status },
//             ...(error as any).response && { response: (error as any).response }
//           });
//         }
//       }
//     };

//     fetchBlogs();
//   }, []);

//   return (
//     <div className={styles.pageWrapper}>
//       {/* Hero Section */}
//       <div className={styles.heroSection}>
//         <div className={styles.heroOverlay} />
//         <div className={styles.heroContent}>
//           <Title1>Our blogs</Title1>
//           <Body1>We keep you up to intelligently chosen IT solutions</Body1>
//         </div>
//       </div>

//       {/* Content Container */}
//       <div className={styles.container}>
//         {/* Filter Section */}
//         <div className={styles.filterSection}>
//           <div className={styles.filterWrapper}>
//             {/* Categories */}
//             <TabList selectedValue={activeCategory} className={styles.tabList}>
//               <Tab value="all" onClick={() => setActiveCategory('all')}>ALL</Tab>
//               <Tab value="azure" onClick={() => setActiveCategory('azure')}>AZURE</Tab>
//               <Tab value="business" onClick={() => setActiveCategory('business')}>BUSINESS INTELLIGENCE</Tab>
//               <Tab value="microsoft" onClick={() => setActiveCategory('microsoft')}>MICROSOFT 365</Tab>
//               <Tab value="sharepoint" onClick={() => setActiveCategory('sharepoint')}>SHAREPOINT</Tab>
//               <Tab value="technical" onClick={() => setActiveCategory('technical')}>TECHNICAL</Tab>
//             </TabList>

//             {/* Search Input */}
//             <input
//               type="text"
//               placeholder="Search articles..."
//               className={styles.searchInput}
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//           </div>
//         </div>

//         {/* Featured Article */}
//         {blogs[0] && (
//           <div className={styles.featuredArticle}>
//             <Card className={styles.featuredCard}>
//               <div className={styles.featuredContent}>
//                 <CardPreview className={styles.featuredImage}>
//                   <img
//                     src={blogs[0].thumbnail.url}
//                     alt={blogs[0].thumbnail.altText}
//                   />
//                 </CardPreview>
//                 <div className={styles.featuredText}>
//                   <span className={styles.featuredBadge}>FEATURED</span>
//                   <Title2>{blogs[0].title.title}</Title2>
//                   <Body1>{blogs[0].metaDescription}</Body1>
//                   <div className={styles.featuredMeta}>
//                     <span>{blogs[0].author}</span>
//                     <span>•</span>
//                     <span>{new Date(blogs[0].updated).toLocaleDateString()}</span>
//                   </div>
//                 </div>
//               </div>
//             </Card>
//           </div>
//         )}

//         {/* Blog Grid */}
//         <div className={styles.blogGrid}>
//           {blogs.slice(1).map((blog) => (
//             <Card key={blog.id} className={styles.blogCard}>
//               <CardPreview>
//                 <img
//                   src={blog.thumbnail.url}
//                   alt={blog.thumbnail.altText}
//                   className={styles.blogImage}
//                 />
//               </CardPreview>
//               <CardHeader>
//                 <div className={styles.blogContent}>
//                   <Title2>{blog.title.title}</Title2>
//                   <Body1>{blog.metaDescription}</Body1>
//                   <div className={styles.blogMeta}>
//                     <span>{blog.author}</span>
//                     <span>•</span>
//                     <span>{new Date(blog.updated).toLocaleDateString()}</span>
//                   </div>
//                 </div>
//               </CardHeader>
//             </Card>
//           ))}
//         </div>

//         {/* Pagination */}
//         <div className={styles.pagination}>
//           {[1, 2, 3, 4, 5].map((page) => (
//             <button
//               key={page}
//               className={`${styles.pageButton} ${page === 1 ? styles.active : ''}`}
//             >
//               {page}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Blog;

import React, { useState } from 'react';
import {
  Title1,
  Title2,
  Body1,
  Card,
  CardHeader,
  CardPreview,
  TabList,
  Tab,
  Badge,
  Avatar,
} from "@fluentui/react-components";
import styles from './Blog.module.scss';

const MOCK_BLOGS = [
  {
    id: 1,
    title: {
      title: "Understanding Azure's Latest Features: AI Integration and Cloud Security"
    },
    metaDescription: "Dive deep into the newest Azure features that are revolutionizing cloud computing. Learn about AI service integration, enhanced security measures, and performance optimizations.",
    author: "John Smith",
    category: "Azure",
    updated: "2024-01-10",
    thumbnail: {
      url: "/api/placeholder/800/400",
      altText: "Azure Cloud Features"
    }
  },
  {
    id: 2,
    title: {
      title: "Power Platform: Building Enterprise Solutions"
    },
    metaDescription: "Discover how to leverage Power Platform to create robust enterprise solutions. From Power Apps to Power Automate, learn the best practices for scalable applications.",
    author: "Sarah Johnson",
    category: "Business Intelligence",
    updated: "2024-01-09",
    thumbnail: {
      url: "/api/placeholder/800/400",
      altText: "Power Platform Development"
    }
  },
  {
    id: 3,
    title: {
      title: "SharePoint Modern Experience: What's New in 2024"
    },
    metaDescription: "Explore the latest updates in SharePoint's modern experience. Learn about new web parts, improved customization options, and enhanced collaboration features.",
    author: "Michael Chen",
    category: "SharePoint",
    updated: "2024-01-08",
    thumbnail: {
      url: "/api/placeholder/800/400",
      altText: "SharePoint Modern"
    }
  },
  {
    id: 4,
    title: {
      title: "Microsoft 365 Security Best Practices"
    },
    metaDescription: "Essential security practices for Microsoft 365 environments. From conditional access to data loss prevention, ensure your organization's data stays protected.",
    author: "Emily Wilson",
    category: "Microsoft 365",
    updated: "2024-01-07",
    thumbnail: {
      url: "/api/placeholder/800/400",
      altText: "M365 Security"
    }
  },
  {
    id: 5,
    title: {
      title: "Technical Deep Dive: Graph API Integration"
    },
    metaDescription: "A comprehensive guide to integrating Microsoft Graph API in your applications. Learn about authentication, best practices, and common implementation patterns.",
    author: "David Kumar",
    category: "Technical",
    updated: "2024-01-06",
    thumbnail: {
      url: "/api/placeholder/800/400",
      altText: "Graph API"
    }
  }
];

const Blog = () => {
  const [activeCategory, setActiveCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className={styles.pageWrapper}>
      {/* Hero Section */}
      <div className={styles.heroSection}>
        <div className={styles.heroOverlay} />
        <div className={styles.heroContent}>
          <Title1>Our blogs</Title1>
          <Body1>We keep you up to intelligently chosen IT solutions</Body1>
        </div>
      </div>

      {/* Content Container */}
      <div className={styles.container}>
        {/* Filter Section */}
        <div className={styles.filterSection}>
          <div className={styles.filterWrapper}>
            <TabList selectedValue={activeCategory} className={styles.tabList}>
              <Tab value="all" onClick={() => setActiveCategory('all')}>ALL</Tab>
              <Tab value="azure" onClick={() => setActiveCategory('azure')}>AZURE</Tab>
              <Tab value="business" onClick={() => setActiveCategory('business')}>BUSINESS INTELLIGENCE</Tab>
              <Tab value="microsoft" onClick={() => setActiveCategory('microsoft')}>MICROSOFT 365</Tab>
              <Tab value="sharepoint" onClick={() => setActiveCategory('sharepoint')}>SHAREPOINT</Tab>
              <Tab value="technical" onClick={() => setActiveCategory('technical')}>TECHNICAL</Tab>
            </TabList>

            <input
              type="text"
              placeholder="Search articles..."
              className={styles.searchInput}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {/* Featured Article */}
        {MOCK_BLOGS[0] && (
          <div className={styles.featuredArticle}>
            <Card className={styles.featuredCard}>
              <div className={styles.featuredContent}>
                <CardPreview className={styles.featuredImage}>
                  <img
                    src={MOCK_BLOGS[0].thumbnail.url}
                    alt={MOCK_BLOGS[0].thumbnail.altText}
                  />
                  <div className={styles.featuredBadgeWrapper}>
                    <Badge appearance="filled" className={styles.featuredBadge}>FEATURED</Badge>
                  </div>
                </CardPreview>
                <div className={styles.featuredText}>
                  <div className={styles.categoryBadge}>
                    <Badge appearance="tint" className={styles.category}>
                      {MOCK_BLOGS[0].category}
                    </Badge>
                  </div>
                  <Title2>{MOCK_BLOGS[0].title.title}</Title2>
                  <Body1>{MOCK_BLOGS[0].metaDescription}</Body1>
                  <div className={styles.featuredMeta}>
                    <div className={styles.authorInfo}>
                      <Avatar
                        name={MOCK_BLOGS[0].author}
                        size={32}
                        className={styles.avatar}
                      />
                      <span className={styles.author}>{MOCK_BLOGS[0].author}</span>
                    </div>
                    <span className={styles.date}>
                      {new Date(MOCK_BLOGS[0].updated).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        )}

        {/* Blog Grid */}
        <div className={styles.blogGrid}>
          {MOCK_BLOGS.slice(1).map((blog) => (
            <Card key={blog.id} className={styles.blogCard}>
              <div className={styles.cardContent}>
                <div className={styles.cardInner}>
                  <CardPreview className={styles.blogImageWrapper}>
                    <img
                      src={blog.thumbnail.url}
                      alt={blog.thumbnail.altText}
                      className={styles.blogImage}
                    />
                  </CardPreview>
                  <div className={styles.blogContent}>
                    <div className={styles.categoryBadge}>
                      <Badge appearance="tint" className={styles.category}>
                        {blog.category}
                      </Badge>
                    </div>
                    <Title2>{blog.title.title}</Title2>
                    <Body1 className={styles.description}>{blog.metaDescription}</Body1>
                    <div className={styles.blogMeta}>
                      <div className={styles.authorInfo}>
                        <Avatar
                          name={blog.author}
                          size={24}
                          className={styles.avatar}
                        />
                        <span className={styles.author}>{blog.author}</span>
                      </div>
                      <span className={styles.date}>
                        {new Date(blog.updated).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>

        {/* Pagination */}
        <div className={styles.pagination}>
          {[1, 2, 3, 4, 5].map((page) => (
            <button
              key={page}
              className={`${styles.pageButton} ${page === 1 ? styles.active : ''}`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;