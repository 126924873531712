import React, { useState } from "react";
import { Card, Button, Text, Image, makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
    mainContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "2rem",
        margin: "0 auto",
        maxWidth: "1200px",
        flexDirection: "column",
    },
    header: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        marginBottom: "40px",
    },
    headerContent: {
        maxWidth: "800px",
    },
    cardContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: "100%",
        height: "400px",
        overflow: "hidden",
        flexDirection: "column"
    },
    cardWrapper: {
        position: "absolute",
        width: "300px",
        height: "400px",
        transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
    },
    shadowCard: {
        justifyContent: "center",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        borderRadius: "8px",
        overflow: "hidden",
        padding: "20px",
        textAlign: "center",
        backgroundColor: "#fff",
        height: "100%",
    },
    cardImage: {
        display: "flex",
        width: "100%",
        height: "auto",
        padding: 0,
        top: 0,
        left: 0,
        objectFit: "cover",
    },
    cardTitle: {
        display: "flex",
        fontSize: "1.25rem",
        fontWeight: 600,
        marginBottom: "10px",
    },
    cardDescription: {
        display: "flex",
        fontSize: "1rem",
        marginBottom: "15px",
        color: "#555",
    },
    cardButton: {
        display: "flex",
        marginTop: "15px",
    },
    navigation: {
        marginTop: "4rem",
    },
});

const ServicePortfolio = () => {
    const classes = useStyles();
    const [currentIndex, setCurrentIndex] = useState(1);
    const cards = [
        {
            title: "Case Study 1",
            description: "This is case study 1 text",
            image: "https://dolphinwebsiteproduction.blob.core.windows.net/portfolio-uploads/thumbnails/portfolio-aecom-cover.webp",
        },
        {
            title: "Case Study 2",
            description: "This is case study 2 text",
            image: "https://dolphinwebsiteproduction.blob.core.windows.net/portfolio-uploads/thumbnails/portfolio-besso-cover.webp",
        },
        {
            title: "Case Study 3",
            description: "This is case study 3 text",
            image: "https://dolphinwebsiteproduction.blob.core.windows.net/portfolio-uploads/thumbnails/portfolio-data-techniques-cover.webp",
        },
    ];

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? cards.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
    };

    const getCardStyle = (index: number) => {
        const relativeIndex = (index - currentIndex + cards.length) % cards.length;

        if (relativeIndex === 0) {
            // Left card
            return { transform: "translateX(-350px)", opacity: 0.5, zIndex: 1 };
        } else if (relativeIndex === 1) {
            // Center (focused) card
            return { transform: "translateX(0)", opacity: 1, zIndex: 2 };
        } else if (relativeIndex === 2) {
            // Right card
            return { transform: "translateX(350px)", opacity: 0.5, zIndex: 1 };
        } else {
            // Hidden cards (to handle edge cases)
            return { transform: "translateX(1000px)", opacity: 0, zIndex: 0 };
        }
    };

    return (
        <div className={classes.mainContainer}>
            <div className={classes.header}>
                <div className={classes.headerContent}>
                    <Text as="h2" size={800} align="center" block>
                        Our customers speak volumes about us.
                    </Text>
                    <Text as="h4" size={500} style={{ color: "#869ab8" }} align="center" block>
                        We work with companies large and small, all over the world.
                    </Text>
                </div>
            </div>

            <div className={classes.cardContainer}>
                {cards.map((card, index) => (
                    <div
                        key={index}
                        className={classes.cardWrapper}
                        style={getCardStyle(index)}
                    >
                        <Card className={classes.shadowCard}>
                            <Image
                                src={card.image}
                                alt={card.title}
                                className={classes.cardImage}
                            />
                            <Text className={classes.cardTitle} size={400} weight="semibold">
                                {card.title}
                            </Text>
                            <Text className={classes.cardDescription} size={300}>
                                {card.description}
                            </Text>
                            <Button appearance="secondary" className={classes.cardButton}>
                                Read More
                            </Button>
                        </Card>
                    </div>
                ))}
            </div>

            <div className={classes.navigation}>
                <Button onClick={handlePrev}>Previous</Button>
                <Button onClick={handleNext}>Next</Button>
            </div>
        </div>
    );
};

export default ServicePortfolio;
