import ServicePageLayout from "../ServicePageLayout";

export default function Teams() {

    const banner = {
        title: "Microsoft Teams",
        subtitle: "Stay in the loop and achieve more with online meetings on Microsoft Teams",
        description: "Interested to hear more?",
        buttonText: "Get In Touch",
        onButtonClick: () => {
            console.log("CTA Button Clicked! Redirect to contact page.");
        },
        imageSrc: "https://www.consultdolphin.com/images/pages/teams/image-1.webp",
        imageAlt: "Microsoft Teams"
    };

    const featuresAlt = [
        {
            title: "Teams and Channels",
            description:
                "Organise your internal structure by creating teams and channels within them to categorise content such as topics, projects etc.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-8.png",
            imageAlt: "Teams and Channels",
        },
        {
            title: "Document Storage",
            description:
                "Keep all your shared files in one place - when a team is created, a new SharePoint site is also created and connected to it.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-6.png",
            imageAlt: "Document Storage",
        },
        {
            title: "Online Meetings & Video Calls",
            description:
                "Enhance communication with no limitations and have online meetings inside or outside your business.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-7.png",
            imageAlt: "Online Meetings & Video Calls",
        },
        {
            title: "Audio Conferencing",
            description:
                "Join online meetings via phone dial - don't miss a minute of your most important meetings.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-2.png",
            imageAlt: "Audio Conferencing",
        }
    ];

    const slides = [
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'CONVENIENT',
                heading: 'Simplify Communication with Microsoft Teams',
                paragraph:
                    'The most convenient digital workplace messaging application as it comes as a part of the Microsoft 365 package.',
                services: [
                    'Microsoft Teams Setup and Deployment',
                    'Team Channel Organization and Management',
                    'Integrated Messaging and Task Tools',
                ],
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'MAKE IT PERSONAL',
                heading: 'Personalized Collaboration for Your Team',
                paragraph:
                    'Video calls make online meetings feel more personal, and you can also share and store files in the document storage, hosted on SharePoint.',
                services: [
                    'Video Conferencing Customization and Training',
                    'Enhanced Teams File Storage Solutions',
                    'Custom Permissions for Teams and Channels',
                ],
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'INTEGRATION',
                heading: 'Unify Your Tools with Microsoft Teams Integration',
                paragraph:
                    "It's downloadable as an app, but it can also be used online. Setting up Microsoft Teams is simple, requiring only the most basic details such as email and password. Teams also seamlessly integrates with other Microsoft products, as well as external applications such as Trello, Slack etc, keeping the flow of work uninterrupted while you collaborate with your colleagues.",
                services: [
                    'Integration with Third-Party Applications like Trello and Slack',
                    'Advanced Workflows for Microsoft Teams and Power Automate',
                    'Seamless Synchronization with Outlook and SharePoint',
                ],
            },
        },
    ];

    const imageText = {
        heading: "Empower Collaboration with Microsoft Teams",
        description1: "Microsoft Teams is your all-in-one hub for teamwork, bringing together chat, video meetings, file sharing, and collaborative tools in one platform. Whether you’re working in the office or remotely, Teams keeps your entire organization connected and productive.",
        description2: "From organizing seamless virtual meetings to integrating with your favorite tools like Outlook, SharePoint, and third-party apps, Microsoft Teams makes teamwork simple and effective. Let us help you set up and optimize Teams for your business.",
        buttonText: "Get Started with Teams",
        buttonLink: "/services/contact",
        imageSrc: "https://www.consultdolphin.com/images/pages/teams/image-2.webp",
        imageAlt: "Microsoft Teams Solutions"
    };

    return (
        <ServicePageLayout
            banner={banner}
            featuresAlt={{ title: "Built for teams of all kinds.", subtitle: "No matter what you're working on, who you're with, or how many of you there are, SharePoint can help.", cards: featuresAlt }}
            slides={slides}
            imageText={imageText}
            includePortfolio={false}
        />
    );
}