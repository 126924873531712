import ServicePageLayout from "../ServicePageLayout";

export default function Webcon() {

    const banner = {
        title: "WEBCON BPS",
        subtitle: "Embrace the future of business efficiency.",
        description: "Interested to hear more?",
        buttonText: "Get In Touch",
        onButtonClick: () => {
            console.log("CTA Button Clicked! Redirect to contact page.");
        },
        imageSrc: "https://www.consultdolphin.com/images/pages/bps/banner.jpg",
        imageAlt: "WEBCON Business Process Solutions"
    };

    const features = [
        {
            title: "Task Automation.",
            description:
                "Offers task automation, carried out as part of a wider automation throughout the entire business process.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-8.png",
            imageAlt: "Task Automation",
            imagePosition: "left",
        },
        {
            title: "Low Code.",
            description:
                "WEBCON BPS uses a low-code development technique for developing process management solutions.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-6.png",
            imageAlt: "Low Code",
            imagePosition: "right",
        },
        {
            title: "All Around Solution.",
            description:
                "From handling support to mission critical processes, WEBCON BPS delivers a tailored, process-centric solution for all types of businesses. This platform allows you to build a workflow-based solution that integrates with existing Line of Business systems, and quickly responds to ever-changing business needs.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-2.png",
            imageAlt: "All Around Solution",
            imagePosition: "left",
            link: "/services/document-management",
            layout: "full",
        },
    ];

    const slides = [
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'FAST DEVELOPMENT',
                heading: 'Revolutionize Your Workflow with Rapid Application Delivery',
                paragraph:
                    'WEBCON BPS accelerates your digital transformation by enabling rapid application development with minimal coding. Leverage pre-built components and agile methodologies to deliver robust solutions tailored to your business needs. With WEBCON BPS, you’ll reduce development time, improve efficiency, and achieve scalable results in record time.',
                services: [
                    'Agile Software Development with Low-Code Solutions',
                    'Custom Workflow Automation and Optimization',
                    'Enterprise-Grade Applications Built at Speed',
                ],
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'MICROSOFT INTEGRATION',
                heading: 'Unleash the Full Potential of Microsoft 365 and Beyond',
                paragraph:
                    'WEBCON BPS seamlessly integrates with Microsoft 365, SharePoint, SQL Server, and other platforms, allowing you to centralize operations and enhance collaboration. Its powerful API and REST capabilities ensure smooth connectivity to external systems, providing a unified and dynamic digital ecosystem.',
                services: [
                    'Comprehensive Integration with Microsoft Tools',
                    'Custom Solutions to Leverage Microsoft’s Ecosystem',
                    'Data Synchronization Across Platforms for Better Insights',
                ],
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'REUSABILITY',
                heading: 'Achieve Efficiency with WEBCON’s Reusable Components',
                paragraph:
                    'WEBCON BPS champions the principle of "reuse, don’t rebuild," allowing organizations to optimize their solutions by reusing workflows, components, and integrations. This not only saves time and resources but also ensures consistency and reliability in every project.',
                services: [
                    'Reusable Workflow and Process Templates',
                    'Streamlined Upgrades Across Platforms and Versions',
                    'Consistent and Scalable Solutions for Long-Term Success',
                ],
            },
        },
    ];

    const imageText = {
        heading: "Supercharge Your Business with WEBCON BPS",
        description1: 
            "WEBCON BPS is a powerful low-code platform designed to transform how businesses operate. From streamlining workflows to automating complex processes, WEBCON delivers unmatched efficiency and scalability for enterprises of all sizes.",
        description2: 
            "Leverage seamless integration with Microsoft 365, SharePoint, and SQL Server to unlock new levels of productivity. Our expert team will guide you in creating tailored, reusable solutions that align with your business objectives and drive success.",
        buttonText: "Explore WEBCON Solutions",
        buttonLink: "/services/webcon",
        imageSrc: "https://www.consultdolphin.com/images/pages/bps/image-1.jpg",
        imageAlt: "WEBCON Business Process Solutions"
    };

    return (
        <ServicePageLayout
            banner={banner}
            features={{ title: "Truly makes a difference.", subtitle: "By choosing WEBCON as your process management software, you provide your business with one unified digital workplace for your end-users.", cards: features }}
            slides={slides}
            imageText={imageText}
        />
    );
}