import { Text, makeStyles } from "@fluentui/react-components";

interface TitleTextProps {
  title: string;
  subtitle: string;
  alignment?: "start" | "center"; // New prop for alignment
}

const useStyles = makeStyles({
  textSection: {
    marginBottom: "2rem",
    width: "40%",
    padding: "0 2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    '@media (max-width: 768px)': {
      width: "100%",
      alignItems: "center",
      textAlign: "center", // Center text on smaller screens
    },
  },
  blueText: {
    color: "#67b0d1",
  },
  centerAlign: {
    alignItems: "center",
    textAlign: "center", // Center text when alignment is "center"
  },
});

export default function TitleText(props: TitleTextProps) {
  const classes = useStyles();
  const words = props.title.split(" ");
  const firstPart = words.slice(0, words.length - 2).join(" "); // All except last two words
  const lastTwoWords = words.slice(-2).join(" "); // Last two words

  // Dynamically apply the 'centerAlign' class if alignment is 'center'
  const alignmentClass = props.alignment === "center" ? classes.centerAlign : "";

  return (
    <div className={`${classes.textSection} ${alignmentClass}`}>
      <Text as="h2" size={800} block align={props.alignment}>
        {firstPart} <span className={classes.blueText}>{lastTwoWords}</span>
      </Text>
      <Text as="h4" size={500} block style={{ color: "#869ab8" }} align={props.alignment}>
        {props.subtitle}
      </Text>
    </div>
  );
}