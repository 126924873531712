import { useEffect, useState } from 'react';
import {
  Text,
  Title3,
  Card,
  CardHeader,
  Button,
  Link,
  LargeTitle,
  Image,
} from "@fluentui/react-components";
import blogService from '../services/BlogService';
import portfolioService from '../services/PortfolioService';
import testimonialService from '../services/TestimonialService';
import ICaseStudy from '../models/ICaseStudy';
import IBlogPost from '../models/IBlogPost';
import ITestimonial from '../models/ITestimonial';
import styles from './Home.module.scss';

import {
  ChevronLeft24Regular,
  ChevronRight24Regular,
  ArrowRight16Regular,
  Checkmark16Regular
} from "@fluentui/react-icons";
import { Container, Row, Col } from 'react-grid-system';
//import { Form } from 'react-router-dom';

export default function Home() {
  const [caseStudies, setCaseStudies] = useState<ICaseStudy[]>([]);
  const [blogs, setBlogPosts] = useState<IBlogPost[]>([]);
  const [testimonials, setTestimonials] = useState<ITestimonial[]>([]);
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  const [currentWhoWeAreSlide, setCurrentWhoWeAreSlide] = useState(0);

  const whoWeAreImages = [
    "https://www.consultdolphin.com/images/pages/home/img-vertical.jpg",
    "https://www.consultdolphin.com/images/pages/home/img-horizontal2.jpg",
    "https://www.consultdolphin.com/images/pages/home/img-horizontal.jpg",
    "https://www.consultdolphin.com/images/pages/home/img-vertical2.jpg"
  ];

  const services = [
    'SharePoint',
    'Microsoft .NET',
    'Power Platform',
    'Processes and Workflow',
    'Support and Managed Services'
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogResponse: IBlogPost[] = await blogService.getBlogs();
        const caseStudyResponse: ICaseStudy[] = await portfolioService.getCaseStudies();
        const testimonialResponse: ITestimonial[] = await testimonialService.getTestimonials();
        setBlogPosts(blogResponse);
        setCaseStudies(caseStudyResponse);
        setTestimonials(testimonialResponse);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Initialize video
    const video = document.getElementById('bgVideo') as HTMLVideoElement;
    if (video) {
      const source = video.querySelector('source');
      if (source?.dataset.src) {
        source.src = source.dataset.src;
        video.load();
      }
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTestimonial((prev) =>
        prev === testimonials.length - 1 ? 0 : prev + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <div>
      {/* Video Background */}
      <div className={styles.heroSection}>
        <div className={styles.videoContainer}>
          <video
            className={styles.backgroundVideo}
            autoPlay
            loop
            muted
            playsInline
          >
            <source
              src="https://www.consultdolphin.com/images/pages/home/top-banner-video-h264.mp4"
              type="video/mp4"
            />
          </video>
          <div className={styles.videoOverlay}></div>
        </div>

        <Container className={styles.heroContainer}>
          <Row justify="center" align="center">
            <Col md={8}>
              <LargeTitle as="h1" align="center" block>
                Redefine Success With Strategic IT Modernization
              </LargeTitle>
              <Text as="h3" size={500} align="center" block>
                Digitally transform the way you work through Microsoft, Business Process
                Management Solutions, custom-tailored software, and more.
              </Text>
              <Button appearance="primary" className={styles.ctaButton}
                onMouseOver={(e) => ((e.target as HTMLButtonElement).style.transform = 'translateY(-2px)')}
                onMouseOut={(e) => ((e.target as HTMLButtonElement).style.transform = 'none')}
              >
                Start your journey
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Main Content */}
      <Container className={styles.container}>
        {/* Mission Statement */}
        <Row justify="center">
          <Col md={8}>
            <Text as="h2" size={800} align="center" block>
              Our mission is to help businesses transform the way
              <Text as="h2" size={800} align="center" style={{ color: "#6EB2D4" }}> they operate...</Text>
            </Text>
            <Text as="h4" size={500} style={{ color: "#869ab8" }} align="center" block>
              ...through intelligent IT Solutions and Support.
            </Text>
          </Col>
        </Row>

        {/* Services */}
        <Row className={styles.row} justify="center">
          {/* Card 1 */}
          <Col xs={12} md={4} className={styles.cardCol}>
            <Card className={styles.card}>
              <CardHeader
                header={
                  <Title3 style={{ padding: '20px 20px 0 20px' }}>
                    Microsoft Products
                  </Title3>
                }
                description={
                  <Text as="p" size={400} style={{ padding: '10px 20px', color: "#869ab8" }}>
                    Discover a suite of Microsoft products that seamlessly combine affordability, scalability, and outstanding performance—offering the ideal solution for small applications. Elevate your business efficiency with cutting-edge technology that not only fits your budget but also scales with your growth.
                  </Text>
                }
              />
              <Link className={styles.learnMoreLink} href="/service/microsoftoffice">
                <Text className={styles.textLink}>Learn More</Text> <ArrowRight16Regular style={{ marginBottom: "-2px" }} />
              </Link>
            </Card>
          </Col>

          {/* Card 2 */}
          <Col xs={12} md={4} className={styles.cardCol}>
            <Card className={styles.card}>
              <CardHeader
                header={
                  <Title3 style={{ padding: '20px 20px 0 20px' }}>
                    Business Process Solutions
                  </Title3>
                }
                description={
                  <Text as="p" size={400} style={{ padding: '10px 20px', color: "#869ab8" }}>
                    Empower your organization with our Business Process Solutions, designed to propel efficiency through strategic and automated business process management. Streamline your workflows, optimize resource utilization, and enhance overall productivity with our comprehensive suite of solutions.
                  </Text>
                }
              />
              <Link className={styles.learnMoreLink} href="/service/businesssolutions">
                <Text className={styles.textLink}>Learn More</Text> <ArrowRight16Regular style={{ marginBottom: "-2px" }} />
              </Link>
            </Card>
          </Col>

          {/* Card 3 */}
          <Col xs={12} md={4} className={styles.cardCol}>
            <Card className={styles.card}>
              <CardHeader
                header={
                  <Title3 style={{ padding: '20px 20px 0 20px' }}>
                    Bespoke Software
                  </Title3>
                }
                description={
                  <Text as="p" size={400} style={{ padding: '10px 20px', color: "#869ab8" }}>
                    Our team of experts collaborates closely with you to understand your unique challenges, ensuring that every line of code aligns with your vision and objectives. No more one-size-fits-all solutions - embrace software that precisely matches your workflows, offering a new level of flexibility that you can't get elsewhere.
                  </Text>
                }
              />
              <Link className={styles.learnMoreLink} href="/service/dotnet">
                <Text className={styles.textLink}>Learn More</Text> <ArrowRight16Regular style={{ marginBottom: "-2px" }} />
              </Link>
            </Card>
          </Col>
        </Row>

        {/* Clients */}
        <Row className={styles.contentContainer} justify="center">
          <Col md={12} style={{ textAlign: "center" }}>
            <Text as="h2" size={600} weight="semibold" align="center" style={{ textTransform: "uppercase" }}>
              Companies that trust us
            </Text>
          </Col>
        </Row>
        <Row className={styles.row} justify="center">
          {['macro', 'epuki', 'leightons', 'woodmansterne', 'tesco', 'vw'].map((company) => (
            <Col key={company} md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Image
                src={`https://dolphinwebsiteproduction.blob.core.windows.net/portfolio-uploads/logos/${company}.png`}
                alt={company}
                style={{ maxWidth: '100%', maxHeight: '100px' }}
              />
            </Col>
          ))}
        </Row>

        {/* Who We Are */}
        <Row className={styles.whoWeAreContainer}>
          <Col md={12}>
            <div className={styles.innerContainer}>
              <div className={styles.imageCard}>
                <Image
                  src={whoWeAreImages[currentWhoWeAreSlide]}
                  alt={`Slide ${currentWhoWeAreSlide + 1}`}
                  className={styles.image}
                />
                <div className={styles.overlay} />

                <div className={styles.navigationContainer}>
                  <Button
                    icon={<ChevronLeft24Regular />}
                    appearance="subtle"
                    onClick={() => setCurrentWhoWeAreSlide(prev =>
                      prev === 0 ? whoWeAreImages.length - 1 : prev - 1
                    )}
                    aria-label="Previous slide"
                  />
                  <Button
                    icon={<ChevronRight24Regular />}
                    appearance="subtle"
                    onClick={() => setCurrentWhoWeAreSlide(prev =>
                      prev === whoWeAreImages.length - 1 ? 0 : prev + 1
                    )}
                    aria-label="Next slide"
                  />
                  <Text as="span" className={styles.counter}>
                    {String(currentWhoWeAreSlide + 1).padStart(2, '0')} / {String(whoWeAreImages.length).padStart(2, '0')}
                  </Text>
                </div>
              </div>

              <Card className={styles.contentCard}>
                <div>
                  <Text as="span" className={styles.badge}>WHO WE ARE</Text>
                  <Text as="h3" size={700} weight="semibold" block>
                    Our team can help you with Solution Development
                  </Text>

                  <Text as="p" size={300} style={{ marginBottom: "1.5rem" }}>
                    We are a holistic and full lifecycle IT development business - your one-stop-shop for migration, cloud hosted solutions development, support and business process management.
                  </Text>

                  <div className={styles.serviceList}>
                    {services.map((service, index) => (
                      <div key={index} className={styles.serviceItem}>
                        <Checkmark16Regular className={styles.checkIcon} />
                        <Text>{service}</Text>
                      </div>
                    ))}
                  </div>
                </div>
              </Card>
            </div>
          </Col>
        </Row>

        {/* Portfolio */}
        <Row className={styles.row} justify="center">
          <Col md={12}>
            <div className={styles.sectionHeader}>
              <Text as="span" className={styles.blueBadge}>OUR PORTFOLIO</Text>
            </div>
            <Text as="h2" size={700} align="center" block>
              Read our case studies.
            </Text>
            <Text as="h5" size={500} align="center" block>
              See how we solved many of our clients' problems.
            </Text>
          </Col>
        </Row>
        <Row className={styles.row} justify="center">
          {caseStudies.slice(0, 3).map((caseStudy) => (
            <Col md={4} sm={6} xs={12} key={caseStudy.id} className={styles.cardCol}>
              <Card className={styles.card}>
                <Image
                  src={caseStudy.thumbnail.url}
                  alt={caseStudy.thumbnail.altText}
                  style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
                <CardHeader>
                  <Text>{caseStudy.client.name}</Text>
                </CardHeader>
                <Text>{caseStudy.subtitle}</Text>
                <Text>
                  {caseStudy.client.industry || 'N/A'}
                </Text>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Blog */}
        <Row className={styles.row} justify="center">
          <Col md={12}>
            <div className={styles.sectionHeader}>
              <Text as="span" className={styles.blueBadge}>OUR BLOG</Text>
            </div>
            <Text as="h2" size={700} align="center" block>
              Stay up to date with our news.
            </Text>
          </Col>
        </Row>

        <Row className={styles.row} justify="center">
          {blogs.slice(0, 3).map((blog) => (
            <Col key={blog.id} xs={12} sm={12} md={4} className={styles.cardCol}>
              <Card className={styles.card}>
                <Image
                  src={blog.thumbnail.url}
                  alt={blog.thumbnail.altText}
                  className={styles.cardImage}
                />
                <Text className={styles.cardTitle} size={400} weight="semibold">
                  {blog.title.title}
                </Text>
                <Text className={styles.cardDescription} size={300}>
                  {blog.metaDescription}
                </Text>
                <Button appearance="secondary" className={styles.cardButton}>
                  Read More
                </Button>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Testimonials */}
        <Row className={styles.row} justify="center">
          <Col md={12} style={{ textAlign: "center" }}>
            <div className={styles.sectionHeader}>
              <Text as="span" className={styles.blueBadge}>REVIEWS</Text>
            </div>
            <Text as="h2" size={700} align="center" block>
              Our clients love us.
            </Text>
            <div className={styles.reviewCarousel}>
              <div className={styles.carouselSlide}>
                {testimonials[activeTestimonial] && (
                  <>
                    <Image
                      src={testimonials[activeTestimonial].client.logo.url}
                      alt={testimonials[activeTestimonial].client.logo.altText}
                    />
                    <Text as="h3" size={700} align="center" block>
                      "{testimonials[activeTestimonial].content.length > 170
                        ? <>
                          {testimonials[activeTestimonial].content.substring(0, 170)}...
                          <Link href="/testimonials">
                            Read more
                          </Link>
                        </>
                        : testimonials[activeTestimonial].content}"
                    </Text>
                    <Text as="p" size={400}>
                      {testimonials[activeTestimonial].client.name}
                    </Text>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>

        {/* Contact Form */}
        
      </Container >
    </div >
  );
}