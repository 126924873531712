import { Button, Menu, MenuTrigger, MenuPopover, MenuList, MenuGroup, MenuGroupHeader, MenuItemLink, makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
    button: {
        padding: "10px"
    },
    storyMenuList: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "1rem",
    },
    serviceMenuList: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "1rem",
    },
    group: {
        padding: "10px 15px",
    },
    header: {
        color: "#67b0d1",
        fontSize: "0.75rem",
        fontWeight: 600,
        textTransform: "uppercase",
        marginTop: "0.5em",
    },
});

export default function DesktopMenu() {
    const styles = useStyles();

    return (
        <>
            <Button as="a" appearance="subtle" className={styles.button}>
                Home
            </Button>
            <Menu positioning={{ autoSize: true }} openOnHover hoverDelay={0}>
                <MenuTrigger>
                    <Button appearance="subtle" className={styles.button}>Our Story</Button>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList className={styles.storyMenuList}>
                        <MenuGroup className={styles.group}>
                            <MenuGroupHeader className={styles.header}>About</MenuGroupHeader>
                            <MenuItemLink href="/ourstory">Our Story</MenuItemLink>
                            <MenuItemLink href="/partners">Partners</MenuItemLink>
                            <MenuItemLink href="/jobs">Job Listing</MenuItemLink>
                            <MenuItemLink href="/sustainability">Sustainability</MenuItemLink>
                        </MenuGroup>
                        <MenuGroup className={styles.group}>
                            <MenuGroupHeader className={styles.header}>Portfolio</MenuGroupHeader>
                            <MenuItemLink href="/casestudies">Case Studies</MenuItemLink>
                            <MenuItemLink href="/testimonials">Testimonials</MenuItemLink>
                            <MenuItemLink href="/awards">Awards</MenuItemLink>
                        </MenuGroup>
                    </MenuList>
                </MenuPopover>
            </Menu>
            <Menu positioning={{ autoSize: true }} openOnHover hoverDelay={0}>
                <MenuTrigger>
                    <Button appearance="subtle" className={styles.button}>Services</Button>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList className={styles.serviceMenuList}>
                        <MenuGroup className={styles.group}>
                            <MenuGroupHeader className={styles.header}>Microsoft</MenuGroupHeader>
                            <MenuItemLink href="/services/sharepoint">SharePoint</MenuItemLink>
                            <MenuItemLink href="/services/dynamics">Dynamics 365</MenuItemLink>
                            <MenuItemLink href="/services/microsoft365">Microsoft 365</MenuItemLink>
                            <MenuItemLink href="/services/teams">Teams</MenuItemLink>
                        </MenuGroup>
                        <MenuGroup className={styles.group}>
                            <MenuGroupHeader className={styles.header}>Solutions</MenuGroupHeader>
                            <MenuItemLink href="/services/processmanagement">Process Management</MenuItemLink>
                            <MenuItemLink href="/services/documentmanagement">Document Management</MenuItemLink>
                            <MenuItemLink href="/services/helpdesk">Helpdesk and Support</MenuItemLink>
                            <MenuItemLink href="/services/crm">CRM</MenuItemLink>
                        </MenuGroup>
                        <MenuGroup className={styles.group}>
                            <MenuGroupHeader className={styles.header}>Power Platform</MenuGroupHeader>
                            <MenuItemLink href="/services/powerbi">Power BI</MenuItemLink>
                            <MenuItemLink href="/services/powerautomate">Power Automate</MenuItemLink>
                            <MenuItemLink href="/services/powerapps">PowerApps</MenuItemLink>
                        </MenuGroup>
                        <MenuGroup className={styles.group}>
                            <MenuGroupHeader className={styles.header}>Custom Software</MenuGroupHeader>
                            <MenuItemLink href="/services/netdev">.NET Development</MenuItemLink>
                            <MenuItemLink href="/services/mobiledevelopment">Mobile App Development</MenuItemLink>
                        </MenuGroup>
                        <MenuGroup className={styles.group}>
                            <MenuGroupHeader className={styles.header}>Cloud</MenuGroupHeader>
                            <MenuItemLink href="/services/azurehosting">Azure Hosting</MenuItemLink>
                            <MenuItemLink href="/services/azuresupport">Azure Support</MenuItemLink>
                            <MenuItemLink href="/services/cloudhosting">Cloud Hosting</MenuItemLink>
                        </MenuGroup>
                        <MenuGroup className={styles.group}>
                            <MenuGroupHeader className={styles.header}>Partnerships</MenuGroupHeader>
                            <MenuItemLink href="/services/mozzaik365">Mozzaik365</MenuItemLink>
                            <MenuItemLink href="/services/dell">Dell Hardware</MenuItemLink>
                            <MenuItemLink href="/services/cyberessentials">Cyber Essentials</MenuItemLink>
                            <MenuItemLink href="/services/pentest">Penetration Testing</MenuItemLink>
                        </MenuGroup>
                    </MenuList>
                </MenuPopover>
            </Menu>
            <Button as="a" appearance="subtle" className={styles.button}>
                Blog
            </Button>
            <Button as="a" appearance="primary" className={styles.button}>
                Contact
            </Button>
        </>
    );
}