import { Text, Image } from "@fluentui/react-components";
import { Container, Row, Col } from "react-grid-system";
import styles from "./ServiceImageText2.module.scss";
import { Checkmark24Regular } from "@fluentui/react-icons";

interface ServiceImageTextProps {
    heading: string;
    points: { heading: string, text: string }[];
    imageSrc: string;
    imageAlt: string;
}

const ServiceImageText2: React.FC<ServiceImageTextProps> = ({
    heading,
    points,
    imageSrc,
    imageAlt,
}) => {
    return (
        <Container className={styles.mainContainer}>
            <Row>
                {/* Left Column: Text and CTA */}
                <Col md={6}>
                    <div className={styles.textContent}>
                        <Text as="h2" size={700} block>
                            {heading}
                        </Text>
                        <ul className={styles.pointList}>
                            {points.map((point, index) => (
                                <li key={index} className={styles.pointItem}>
                                    <div>
                                        <Text as="h4" size={500} block>
                                            <Checkmark24Regular className={styles.checkIcon} />
                                            {point.heading}
                                        </Text>
                                        <Text as="h5" size={400} block style={{ color: "#869ab8", marginLeft: "2.2em" }}>
                                            {point.text}
                                        </Text>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Col>

                <Col md={6} className={styles.imageColumn}>
                    <Image
                        src={imageSrc}
                        alt={imageAlt}
                        className={styles.image}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default ServiceImageText2;