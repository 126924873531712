import ServicePageLayout from "../ServicePageLayout";

export default function SharePoint() {

    const banner = {
        title: "Microsoft SharePoint",
        subtitle: "An intelligent intranet that connects you, your teams, and your organization",
        description: "Interested to hear more?",
        buttonText: "Get In Touch",
        onButtonClick: () => {
            console.log("CTA Button Clicked! Redirect to contact page.");
        },
        imageSrc: "https://www.consultdolphin.com/images/pages/sharepoint/image-3.webp",
        imageAlt: "SharePoint Intranet"
    };

    const features = [
        {
            title: "Communication & Collaboration.",
            description:
                "Team-only access to sites and documents, and the ability to edit files simultaneously.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-8.png",
            imageAlt: "Communication & Collaboration",
            imagePosition: "left",
        },
        {
            title: "Business Process Automation.",
            description:
                "Eliminate repetitive tasks and increase productivity and collaboration. Integrate with WEBCON for a powerful process automation.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-6.png",
            imageAlt: "Business Process Automation",
            imagePosition: "right",
        },
        {
            title: "Document Management.",
            description:
                "SharePoint intranets can also be used as Document Management Systems to help your employees become more productive and efficient. Microsoft SharePoint enables users to easily manage their documents & folders, with all the information stored in a central location.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-2.png",
            imageAlt: "Document Management",
            imagePosition: "left",
            link: "/services/document-management",
            layout: "full",
        },
    ];

    const slides = [
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'INTRANET SOLUTIONS',
                heading: 'Intelligent Intranet Solutions Tailored for Your Needs',
                paragraph:
                    'Discover a range of intelligent intranet solutions powered by SharePoint, available on-premises or online. Enhance collaboration and streamline your organization’s workflow with our advanced tools.',
                services: [
                    'Custom SharePoint Intranet Solutions',
                    'SharePoint Online and On-Premises Hosting',
                    'Collaborative Tools Integration',
                ],
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'DEVELOPMENT',
                heading: 'Digital Transformation from Concept to Delivery',
                paragraph:
                    'Entrust us with your digital transformation journey. From initial consultation to design, build, and delivery, we create bespoke SharePoint intranet solutions that perfectly fit your business needs.',
                services: [
                    'End-to-End SharePoint Development',
                    'Custom Workflow Design',
                    'Bespoke Solutions Tailored to Your Goals',
                ],
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'MIGRATION',
                heading: 'Seamless Data Migration to SharePoint and Microsoft 365',
                paragraph:
                    'Leverage our expertise in planning and performing data migrations. Whether you are transitioning from a different system or upgrading between versions, we ensure a smooth, efficient migration experience.',
                services: [
                    'Data Migration Strategy and Planning',
                    'System-to-SharePoint Migrations',
                    'Version-to-Version Upgrades within SharePoint and Microsoft 365',
                ],
            },
        },
    ];

    const imageText = {
        heading: "Unlock the Power of SharePoint",
        description1: "SharePoint is the ultimate platform for collaboration, document management, and business process automation. With SharePoint Online and On-Premises solutions, we help businesses like yours streamline operations, enhance productivity, and enable seamless teamwork.",
        description2: "Whether you’re building an intranet, migrating to Microsoft 365, or automating workflows, our expert team will design and implement the perfect solution tailored to your business needs.",
        buttonText: "Get Started with SharePoint",
        buttonLink: "/services/contact",
        imageSrc: "https://www.consultdolphin.com/images/pages/sharepoint/image-1.webp",
        imageAlt: "SharePoint Solutions"
    };

    return (
        <ServicePageLayout
            banner={banner}
            features={{ title: "Built for teams of all kinds.", subtitle: "No matter what you're working on, who you're with, or how many of you there are, SharePoint can help.", cards: features }}
            slides={slides}
            imageText={imageText}
        />
    );
}