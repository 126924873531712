import ServicePageLayout from "../ServicePageLayout";

export default function PowerAutomate() {

    const banner = {
        title: "Microsoft Power Automate",
        subtitle: "Streamline. Process. Schedule. Repeat.",
        description: "Interested to hear more?",
        buttonText: "Get In Touch",
        onButtonClick: () => {
            console.log("CTA Button Clicked! Redirect to contact page.");
        },
        imageSrc: "https://www.consultdolphin.com/images/pages/powerplatform/powerautomate/banner.webp",
        imageAlt: "Microsoft Power BI"
    };

    const features = [
        {
            title: "Low Code App Building Tool.",
            description:
                "A tool that enables you to create low code visual workflows for a range of different scenarios.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-8.png",
            imageAlt: "Low Code App Building Tool",
            imagePosition: "left",
        },
        {
            title: "Process Automation.",
            description:
                "Through a range of different trigger types, users can build chains of actions that can be automated to enable the elimination of repetitive, manual and time consuming tasks.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-6.png",
            imageAlt: "Process Automation",
            imagePosition: "right",
        },
        {
            title: "Integration.",
            description:
                "Using the large repository of pre-built data connectors, users have everything they need to connect to their data and build workflows with seamless integration.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-2.png",
            imageAlt: "Integration",
            imagePosition: "left",
            link: "/services/document-management",
            layout: "full",
        },
    ];

    const imageText = {
        heading: "We have lots of experience building Power BI dashboards.",
        points: [
            {
                heading: "Streamline Repetitive Tasks",
                text: "Automate mundane tasks using low-code, drag-and-drop tools.",
            },
            {
                heading: "Increase Efficiency",
                text: "Free your time with automation to focus on what you do best.",
            },
            {
                heading: "Automate Processes",
                text: "Schedule business processes to run if you need them, when you need them.",
            },
            {
                heading: "Leverage AI Help",
                text: "Don't work hard - work smart. Automate using built-in AI capabilities.",
            },
        ],
        imageSrc: "https://www.consultdolphin.com/images/pages/bi/powerbi-desktop.png",
        imageAlt: "Power Automate",
    };

    return (
        <ServicePageLayout
            banner={banner}
            featuresAlt2={{ title: "Send out notifications and transform data", subtitle: "Or even collect and move files across solutions.", cards: features }}
            imageText2={imageText}
        />
    );
}