import { Button, Text, Image, makeStyles } from "@fluentui/react-components";
import { Container, Row, Col } from "react-grid-system";

interface ServiceImageTextProps {
    heading: string;
    description1: string;
    description2: string;
    buttonText: string;
    buttonLink: string;
    imageSrc: string;
    imageAlt: string;
}

const useStyles = makeStyles({
    mainContainer: {
        padding: '20px',
    },
    textContent: {
        padding: '20px',
    },
    ctaBtnPrimary: {
        marginTop: '20px',
    },
    imageColumn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            order: -1, // Move image to the top on mobile
        },
    },
    image: {
        width: '100%',
        height: 'auto',
    },
});

const ServiceImageText: React.FC<ServiceImageTextProps> = ({
    heading,
    description1,
    description2,
    buttonText,
    buttonLink,
    imageSrc,
    imageAlt,
}) => {
    const styles = useStyles();

    return (
        <Container className={styles.mainContainer}>
            <Row>
                {/* Left Column: Image */}
                <Col md={6} className={styles.imageColumn}>
                    <Image
                        src={imageSrc}
                        alt={imageAlt}
                        className={styles.image}
                    />
                </Col>

                {/* Right Column: Text and CTA */}
                <Col md={6}>
                    <div className={styles.textContent}>
                        <Text as="h2" size={700} block>
                            {heading}
                        </Text>
                        <Text as="h4" size={400} block style={{ color: "#869ab8" }}>
                            {description1}
                        </Text>
                        <Text as="h4" size={400} block style={{ color: "#869ab8" }}>
                            {description2}
                        </Text>
                        <Button
                            appearance="primary"
                            onClick={() => {
                                window.location.href = buttonLink;
                            }}
                            className={styles.ctaBtnPrimary}
                        >
                            {buttonText}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ServiceImageText;