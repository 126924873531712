import React, { useState } from 'react';
import { Image, Button, Card, Text, makeStyles } from '@fluentui/react-components';
import { ChevronLeft24Regular, ChevronRight24Regular, Checkmark16Regular } from '@fluentui/react-icons';
import TitleText from '../TitleText';

const useStyles = makeStyles({
  mainContainer: {
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: "2rem",
    margin: "0 auto",
    maxWidth: "1200px",
    flexDirection: "column",
  },
  innerContainer: {
    position: 'relative',
    maxWidth: '1200px',
    width: '80%',
    display: 'flex',
    justifyContent: 'flex-start', // Align image and card side by side
    alignItems: 'center',
    gap: '2rem',
  },
  imageCard: {
    flex: 1,
    position: 'relative',
    height: '500px',
    overflow: 'hidden',
    borderRadius: '16px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'opacity 500ms',
  },
  overlay: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Overlay the image with a dark color
  },
  contentCard: {
    position: 'absolute', // Position the card over the image
    right: '-10%', // Slightly overlap to the right
    transform: 'translateY(20%)', // Slightly adjust to position it better after the image
    zIndex: '10',
    width: '50%', // Make the card wider
    padding: '2.5rem',
    borderRadius: '16px',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1rem',
    backgroundColor: 'white', // White background for the card
    '@media (max-width: 768px)': {
      right: '50%',
      transform: 'translate(50%, 20%)', // Center the card horizontally on mobile
      width: '80%', // Adjust width for mobile
      padding: '1.5rem', // Adjust padding for mobile
    },
  },
  badge: {
    padding: '0.4rem 0.8rem',
    borderRadius: '20px',
    fontSize: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    backgroundColor: '#6EB2D4',
    color: 'white',
  },
  serviceList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem',
  },
  serviceItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
  },
  checkIcon: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  navigationContainer: {
    position: 'absolute',
    zIndex: '20',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    // Desktop View: Bottom-left position
    bottom: '2rem',
    left: '2rem',
    '@media (max-width: 768px)': {
      // Mobile View: Center the arrows horizontally at the bottom
      bottom: '2rem', // Keep it at the bottom
      left: '50%', // Center horizontally
      transform: 'translateX(-50%)', // Adjust for exact center alignment
      gap: '1rem', // Adjust gap between the arrows for mobile
    },
  },
  counter: {
    fontSize: '1rem',
    color: 'white',
  },
});

interface ServicesProps {
  title: string,
  subtitle: string,
  slides: {
    image: string;
    text: {
      badge: string;
      heading: string;
      paragraph: string;
      services: string[];
    };
  }[];
}

const ServiceCarousel: React.FC<ServicesProps> = ({ title, subtitle, slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const styles = useStyles();

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className={styles.mainContainer}>
       <TitleText title={title} subtitle={subtitle} alignment="center"/>

        <div className={styles.innerContainer}>
          <div className={styles.imageCard}>
            <Image
              src={slides[currentSlide].image}
              alt={`Slide ${currentSlide + 1}`}
              className={styles.image}
            />
            <div className={styles.overlay} />
            <div className={styles.navigationContainer}>
              <Button
                icon={<ChevronLeft24Regular />}
                appearance="primary"
                onClick={handlePrevSlide}
                aria-label="Previous slide"
              />
              <Button
                icon={<ChevronRight24Regular />}
                appearance="primary"
                onClick={handleNextSlide}
                aria-label="Next slide"
              />
              <Text as="span" className={styles.counter}>
                {String(currentSlide + 1).padStart(2, '0')} / {String(slides.length).padStart(2, '0')}
              </Text>
            </div>
          </div>
          <Card className={styles.contentCard}>
            <div>
              <Text as="span" className={styles.badge}>{slides[currentSlide].text.badge}</Text>
              <Text as="h3" size={700} weight="semibold" block>
                {slides[currentSlide].text.heading}
              </Text>
              <Text as="p" size={300} style={{ marginBottom: '1.5rem' }}>
                {slides[currentSlide].text.paragraph}
              </Text>
              <div className={styles.serviceList}>
                {slides[currentSlide].text.services.map((service, index) => (
                  <div key={index} className={styles.serviceItem}>
                    <Checkmark16Regular className={styles.checkIcon} />
                    <Text>{service}</Text>
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </div>
      </div>
  );
};

export default ServiceCarousel;
