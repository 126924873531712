import ServicePageLayout from "../ServicePageLayout";

export default function PowerApps() {

    const banner = {
        title: "Microsoft PowerApps",
        subtitle: "Develop custom apps for any business need.",
        description: "Interested to hear more?",
        buttonText: "Get In Touch",
        onButtonClick: () => {
            console.log("CTA Button Clicked! Redirect to contact page.");
        },
        imageSrc: "https://www.consultdolphin.com/images/pages/powerplatform/powerapps/banner.webp",
        imageAlt: "Microsoft Power BI"
    };

    const features = [
        {
            title: "Low Code App Building Tool.",
            description:
                "Start building mobile and web apps for any and all of your business needs.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-8.png",
            imageAlt: "Visualisation and Reporting",
            imagePosition: "left",
        },
        {
            title: "Fast Development",
            description:
                "Enjoy features such as pre-built templates, Microsoft 365 compatibility, seamless deployment and advanced integrated functionality.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-6.png",
            imageAlt: "Fast Development",
            imagePosition: "right",
        },
        {
            title: "Scalability.",
            description:
                "uild, deploy and launch professional-grade apps using drag-and-drop simplicity, then roll out continuous improvements to those apps as necessary.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-2.png",
            imageAlt: "Scalability",
            imagePosition: "left",
            layout: "full",
        },
    ];

    const imageText = {
        heading: "Build simple and cost effective applications",
        points: [
            {
                heading: "Develop",
                text: "Build apps quickly and efficiently using existing templates.",
            },
            {
                heading: "Customise",
                text: "Develop apps that perfectly suit the needs of your business.",
            },
            {
                heading: "Integrate",
                text: "Connect to hundreds of datasources with pre-built connectors.",
            },
            {
                heading: "Deploy",
                text: "Release apps when ready and then iterate to improve them.",
            },
        ],
        imageSrc: "https://www.consultdolphin.com/images/pages/bi/powerbi-desktop.png",
        imageAlt: "Power BI",
    };

    return (
        <ServicePageLayout
            banner={banner}
            featuresAlt2={{ title: "Apps can be as simple or complex as required", subtitle: "Enjoy the combination of pre-built templates, Microsoft 365 compatibility, seamless deployment and advanced integrated functionality.", cards: features }}
            imageText2={imageText}
        />
    );
}