import ServicePageLayout from "../ServicePageLayout";

export default function Dynamics() {

    const banner = {
        title: "Microsoft Dynamics 365",
        subtitle: "A flexible, customisable solution to suit everyone's business needs",
        description: "Interested to hear more?",
        buttonText: "Get In Touch",
        onButtonClick: () => {
            console.log("CTA Button Clicked! Redirect to contact page.");
        },
        imageSrc: "https://www.consultdolphin.com/images/pages/dynamics/banner.webp",
        imageAlt: "Dynamics 365"
    };

    const features = [
        {
            title: "Profitability.",
            description:
                "Increase your profitability by streamlining administrative processes in your sales, marketing, and service divisions.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-8.png",
            imageAlt: "Profitability",
            imagePosition: "left",
        },
        {
            title: "Personalised Service.",
            description:
                "Deliver a more personalised service by understanding customer preferences and history to enable technicians to work hands-free by using innovative technology.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-6.png",
            imageAlt: "Personalised Service",
            imagePosition: "right",
        },
        {
            title: "Appointment Tracking.",
            description:
                "Dynamics 365 helps you provide customers with real-time technician location tracking and appointment reminders. The CRM enables you to share quotes, contracts, and scheduling information quickly and easily. It also lets customers self-schedule appointments and monitor service with a customer portal.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-2.png",
            imageAlt: "Appointment Tracking",
            imagePosition: "left",
            link: "/services/document-management",
            layout: "full",
        },
    ];

    const slides = [
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'MAKE BETTER DECISIONS',
                heading: "Transformative Solutions with Dynamics 365 and Intelligent Intranets",
                paragraph:
                    "Automate processes for customer experiences, drive innovation and business decisions with artificial intelligence, and use the Microsoft cloud platform to reduce operating costs, while simplifying deployment across your infrastructure. With Dynamics 365, gain actionable insights, streamline operations, and enhance collaboration seamlessly.",
                services: [
                    "Custom Dynamics 365 and SharePoint Integrations",
                    "Real-Time Data Analytics and Insights",
                    "Automated Workflows Across Platforms",
                    "Enhanced Collaboration Tools for Teams",
                    "Scalable Cloud Solutions Tailored to Your Business"
                ]
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'ADAPT QUICKLY',
                heading: "Transformative Solutions with Dynamics 365 and Intelligent Intranets",
                paragraph:
                    "Automate processes for customer experiences, drive innovation and business decisions with artificial intelligence, and use the Microsoft cloud platform to reduce operating costs, while simplifying deployment across your infrastructure. With Dynamics 365, gain actionable insights, streamline operations, and enhance collaboration seamlessly.",
                services: [
                    "Custom Dynamics 365 and SharePoint Integrations",
                    "Real-Time Data Analytics and Insights",
                    "Automated Workflows Across Platforms",
                    "Enhanced Collaboration Tools for Teams",
                    "Scalable Cloud Solutions Tailored to Your Business"
                ]
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'GROW YOUR BUSINESS',
                heading: "Seamless Data Migration to Dynamics 365 and Microsoft Cloud",
                paragraph:
                    "You can improve your win rate with a dynamic, event-driven sales process powered by Dynamics 365. Build a high-quality pipeline with leads and prospects, measure past actions, and plan for the future with ease. Our migration services ensure a smooth transition with minimal disruption to your business operations.",
                services: [
                    "Comprehensive Data Migration Strategy and Planning",
                    "Legacy System-to-Dynamics 365 Migrations",
                    "Version-to-Version Upgrades Across Microsoft Ecosystems",
                    "Sales and Marketing Optimisation with Dynamics 365",
                    "Custom Reporting and Analytics Tools"
                ]
            },
        },
    ];

    const imageText = {
        heading: "Dynamics 365 Capabilities",
        description1: "Deliver personalised service by understanding your customers’ preferences and tailoring every interaction to their needs. Build stronger connections and improve loyalty by creating experiences that feel uniquely crafted for each customer.",
        description2: "Use LinkedIn interactions to target matched audiences and run multichannel campaigns with relevant messaging. Personalise workflows for every stage of the buying journey and nurture sales-ready leads to boost conversions and drive growth.",
        buttonText: "Get Started with Dynamics 365",
        buttonLink: "/services/contact",
        imageSrc: "https://www.consultdolphin.com/images/pages/dynamics/image-1.webp",
        imageAlt: "Dynamics 365"
    };

    return (
        <ServicePageLayout
            banner={banner}
            features={{ title: "Built for teams of all kinds.", subtitle: "A set of cloud-based Software as a Service (SaaS) applications and services that combines both Customer Relationship Management and ERP components.", cards: features }}
            slides={slides}
            includePortfolio={false}
            imageText={imageText}
        />
    );
}