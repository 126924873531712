import ServicePageLayout from "../ServicePageLayout";

export default function Microsoft365() {

    const banner = {
        title: "Microsoft 365",
        subtitle: "A set of apps that help you stay connected and get things done",
        description: "Interested to hear more?",
        buttonText: "Get In Touch",
        onButtonClick: () => {
            console.log("CTA Button Clicked! Redirect to contact page.");
        },
        imageSrc: "https://www.consultdolphin.com/images/pages/office/image-1.jpg",
        imageAlt: "Microsoft 365"
    };

    const features = [
        {
            title: "Task Tracking.",
            description:
                "Create new plan schedules, assign day to day tasks, and share files with Microsoft Teams.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-8.png",
            imageAlt: "Task Tracking",
            imagePosition: "left",
        },
        {
            title: "File Storage.",
            description:
                "Easily modify and save a file with OneDrive, and securely share documents with external users..",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-6.png",
            imageAlt: "File Storage",
            imagePosition: "right",
        },
        {
            title: "Email & Calendar.",
            description:
                "Host your emails, manage your timetable, and schedule or respond to a meeting invitation with the calendar.",
            imageSrc: "https://www.consultdolphin.com/assets/img/illustrations/illustration-2.png",
            imageAlt: "Email & Calendar",
            imagePosition: "left",
            link: "/services/document-management",
            layout: "full",
        },
    ];

    const slides = [
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'LICENCING',
                heading: "Transformative Solutions with Dynamics 365 and Intelligent Intranets",
                paragraph:
                    "We can supply you with all your Microsoft licensing needs, as well as monitor your licenses and usage, ensuring you get the best value for money from your subscription.",
                services: [
                ]
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'MIGRATION',
                heading: "Transformative Solutions with Dynamics 365 and Intelligent Intranets",
                paragraph:
                    "With our experience in performing large scale migrations for a number of projects we can ensure that you are prepared for this transformation as a business.",
                services: [
                ]
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/sharepoint/image-2.jpg',
            text: {
                badge: 'TRAINING & SUPPORT',
                heading: "Seamless Data Migration to Dynamics 365 and Microsoft Cloud",
                paragraph:
                    "If you have already moved to Microsoft 365, but you and your employees need some help and support to get the most out of your subscription, we have an in-house Helpdesk ready for you to lean on, for when things become unclear or problems occur.",
                services: [
                ]
            },
        },
    ];

    const imageText = {
        heading: "Dynamics 365 Capabilities",
        description1: "Deliver personalised service by understanding your customers’ preferences and tailoring every interaction to their needs. Build stronger connections and improve loyalty by creating experiences that feel uniquely crafted for each customer.",
        description2: "Use LinkedIn interactions to target matched audiences and run multichannel campaigns with relevant messaging. Personalise workflows for every stage of the buying journey and nurture sales-ready leads to boost conversions and drive growth.",
        buttonText: "Get Started with Dynamics 365",
        buttonLink: "/services/contact",
        imageSrc: "https://www.consultdolphin.com/images/pages/dynamics/image-1.webp",
        imageAlt: "Dynamics 365"
    };

    return (
        <ServicePageLayout
            banner={banner}
            features={{ title: "Built for teams of all kinds.", subtitle: "A set of cloud-based Software as a Service (SaaS) applications and services that combines both Customer Relationship Management and ERP components.", cards: features }}
            slides={slides}
            includePortfolio={false}
            imageText={imageText}
        />
    );
}