import React from 'react';
import ServiceBanner from "../../components/layout/service/banner/ServiceBanner";
import ServiceCards from "../../components/layout/service/cards/ServiceCards";
import ServiceCarousel from "../../components/layout/service/carousel/ServiceCarousel";
import ServicePortfolio from "../../components/layout/service/portfolio/ServicePortfolio";
import ServiceImageText from "../../components/layout/service/imageText/ServiceImageText";
import ContactForm from "../../components/layout/general/contactForm/ContactForm";
import styles from "./ServicePageLayout.module.scss";
import ServiceCards2 from '../../components/layout/service/cards2/ServiceCards2';
import ServiceCards3 from '../../components/layout/service/cards3/ServiceCards3';
import ServiceImageText2 from '../../components/layout/service/imageText2/ServiceImageText2';

interface ServicePageLayoutProps {
    banner?: {
        title: string;
        subtitle: string;
        description: string;
        buttonText: string;
        onButtonClick: () => void;
        imageSrc: string;
        imageAlt: string;
    };
    features?: {
        title: string;
        subtitle: string;
        cards: {
            title: string;
            description: string;
            imageSrc: string;
            imageAlt: string;
            imagePosition: string;
            link?: string;
            layout?: string;
        }[];
    };
    featuresAlt?: {
        title: string;
        subtitle: string;
        cards: {
            imageSrc: string;
            imageAlt: string;
            title: string;
            description: string;
        }[];
    },
    featuresAlt2?: {
        title: string;
        subtitle: string;
        cards: {
            title: string;
            description: string;
        }[];
    }
    slides?: {
        image: string;
        text: {
            badge: string;
            heading: string;
            paragraph: string;
            services: string[];
        };
    }[];
    imageText?: {
        heading: string;
        description1: string;
        description2: string;
        buttonText: string;
        buttonLink: string;
        imageSrc: string;
        imageAlt: string;
    };
    imageText2?: {
        heading: string;
        points: { heading: string, text: string }[];
        imageSrc: string;
        imageAlt: string;
    };
    includePortfolio?: boolean;
    includeContactForm?: boolean;
}

const ServicePageLayout: React.FC<ServicePageLayoutProps> = ({
    banner,
    features,
    featuresAlt,
    featuresAlt2,
    slides,
    imageText,
    imageText2,
    includePortfolio = true,
    includeContactForm = true
}) => {
    return (
        <>
            {banner && (
                <ServiceBanner
                    title={banner.title}
                    subtitle={banner.subtitle}
                    description={banner.description}
                    buttonText={banner.buttonText}
                    onButtonClick={banner.onButtonClick}
                    imageSrc={banner.imageSrc}
                    imageAlt={banner.imageAlt}
                />
            )}

            {features && (
                <div className={styles.bgGrey}>
                    <ServiceCards
                        title={features.title}
                        subtitle={features.subtitle}
                        cards={features.cards}
                    />
                </div>
            )}

            {featuresAlt && (
                <div className={styles.bgGrey}>
                    <ServiceCards2
                        title={featuresAlt.title}
                        subtitle={featuresAlt.subtitle}
                        cards={featuresAlt.cards}
                    />
                </div>
            )}

            {featuresAlt2 && (
                <div className={styles.bgGrey}>
                    <ServiceCards3
                        title={featuresAlt2.title}
                        subtitle={featuresAlt2.subtitle}
                        cards={featuresAlt2.cards}
                    />
                </div>
            )}

            {slides && <ServiceCarousel title="Lorem ipsum dolor est test test test test" subtitle="Lorem ipsum dolor est" slides={slides} />}

            {includePortfolio && (
                <div className={styles.bgGrey}>
                    <ServicePortfolio />
                </div>
            )}

            {imageText && (
                <ServiceImageText
                    heading={imageText.heading}
                    description1={imageText.description1}
                    description2={imageText.description2}
                    buttonText={imageText.buttonText}
                    buttonLink={imageText.buttonLink}
                    imageSrc={imageText.imageSrc}
                    imageAlt={imageText.imageAlt}
                />
            )}

            {imageText2 && (
                <ServiceImageText2
                    heading={imageText2.heading}
                    points={imageText2.points}
                    imageSrc={imageText2.imageSrc}
                    imageAlt={imageText2.imageAlt}
                />
            )}

            {includeContactForm && <ContactForm />}
        </>
    );
};

export default ServicePageLayout;