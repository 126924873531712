import { Button, Image, Link } from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";
import { Dismiss24Filled, Navigation24Filled } from "@fluentui/react-icons";
import { useState } from "react";
import { OverlayDrawer, DrawerHeader, DrawerHeaderTitle, DrawerBody } from "@fluentui/react-drawer";  // Import the OverlayDrawer components
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

const useStyles = makeStyles({
  navContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 2rem",
    height: "60px",
    maxWidth: "1500px",
    margin: "0 auto",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    height: "60px",
  },
  menu: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  burgerMenu: {
    display: "none",
    "@media (max-width: 768px)": {
      display: "flex",
    },
  },
  mobileMenu: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "flex-start",
    textAlign: "left"
  }
});

export default function Navigation() {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);  // Drawer state

  return (
    <div className={styles.navContainer}>
      {/* Logo Section */}
      <Link href="/">
        <Image src="https://www.consultdolphin.com/images/logos/dolphin-solutions_logo_blue-black.png" alt="Dolphin IT Solutions Logo" className={styles.logo} />
      </Link>

      {/* Menu Section */}
      <div className={styles.menu}>
        <DesktopMenu />
      </div>

      {/* Burger Menu for Mobile */}
      <Button
        className={styles.burgerMenu}
        appearance="subtle"
        onClick={() => setIsOpen(!isOpen)}  // Toggle drawer on click
        icon={isOpen ? null : <Navigation24Filled />}
      />

      {/* Drawer for Mobile */}
      <OverlayDrawer
        as="aside"
        open={isOpen}
        onOpenChange={(_, { open }) => setIsOpen(open)}
        style={{ right: 0, left: 'auto' }}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Filled />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
          </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
          <div className={styles.mobileMenu}>
            <MobileMenu />
          </div>
        </DrawerBody>
      </OverlayDrawer>
    </div>
  );
}