import ServicePageLayout from "../ServicePageLayout";

export default function AzureHosting() {

    const banner = {
        title: "Microsoft Azure",
        subtitle: "Build, manage, and monitor everything; from simple web apps to complex cloud deployments.",
        description: "Interested to hear more?",
        buttonText: "Get In Touch",
        onButtonClick: () => {
            console.log("CTA Button Clicked! Redirect to contact page.");
        },
        imageSrc: "https://www.consultdolphin.com/images/pages/azure/banner.webp",
        imageAlt: "Microsoft Azure"
    };

    const features = [
        {
            title: "Cloud Hosted Flexible Infrastructure",
            description:
                "If you're considering migrating your IT infrastructure to the Cloud, Azure Hosting solution allows you to resize your resources at any time, depending on your workload.",
        },
        {
            title: "Data Migration",
            description:
                "Our developers have a great deal of experience in designing, planning, and performing data migrations for businesses into the cloud - from creating SQL VM Farms in Azure, to using SQL-as-a-Service to replace your on-premises databases.",
        },
        {
            title: "Security",
            description:
                "There are many tools within Azure, including Network Security Groups, which allow you to whitelist access to VM ports to given services / IP addresses. With Microsoft & Azure, everything is available securely, wherever you are, and on whatever device.",
        }
    ];

    const slides = [
        {
            image: 'https://www.consultdolphin.com/images/pages/azure/image-1.webp',
            text: {
                badge: 'SCALABLE VMS',
                heading: 'Virtual Server Farms',
                paragraph:
                    'Choose the right VM for your workload, and scale from one to thousands of Linux or Windows VM instances in minutes.',
                services: [
                    'Custom VM Configuration for Workload Optimization',
                    'Scalable Cloud Infrastructure for Growing Needs',
                    'High-Performance Virtual Machines for Critical Applications',
                ],
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/azure/image-1.webp',
            text: {
                badge: 'ENTERPRISE APPS',
                heading: 'App Service and Backends',
                paragraph:
                    'Build enterprise-ready web & mobile apps, but you can also deploy them to a scalable and reliable cloud infrastructure.',
                services: [
                    'Enterprise Web and Mobile App Development',
                    'Cloud Deployment and Scaling for Applications',
                    'Backend Integration with Scalable Cloud Infrastructure',
                ],
            },
        },
        {
            image: 'https://www.consultdolphin.com/images/pages/azure/image-1.webp',
            text: {
                badge: 'CLOUD AUTOMATION',
                heading: 'Automated Functions',
                paragraph:
                    'Run your custom code on demand and at scale in the cloud, and let Azure manage and run it instead of you.',
                services: [
                    'Event-Driven Code Execution in the Cloud',
                    'Serverless Architecture for Optimized Performance',
                    'Custom Azure Functions for Automated Processes',
                ],
            },
        },
    ];

    return (
        <ServicePageLayout
            banner={banner}
            featuresAlt2={{ title: "Make the most of your Azure subscription.", subtitle: "Build, run, and manage business applications.", cards: features }}
            slides={slides}
        />
    );
}