import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Button,
    Text,
    Link,
    makeStyles,
} from "@fluentui/react-components";

const useStyles = makeStyles({
    button: {
        textAlign: "left",
        justifyContent: "flex-start",
        margin: "0",
        height: "30px", // Set a consistent height for all buttons
        display: "flex",
        alignItems: "center",
    },
    accordionHeader: {
        padding: 0,
        margin: 0,
        width: "100%",
        "& button": {
            paddingLeft: "6px",
            textAlign: "left",
            justifyContent: "flex-start",
            width: "100%",
            height: "30px", // Ensure the height matches the other buttons
            display: "flex",
            alignItems: "center",
        }
    },
    link: {
        display: "block",
        marginTop: "2px",
    }
});


export default function MobileMenu() {
    const styles = useStyles();

    return (
        <>
            <Button as="a" appearance="subtle" className={styles.button}>
                Home
            </Button>
            <Accordion collapsible>
                <AccordionItem value="1">
                    <AccordionHeader as="div" expandIconPosition="end" className={styles.accordionHeader}>
                        <Button appearance="subtle" className={styles.button}>
                            Our Story
                        </Button>
                    </AccordionHeader>
                    <AccordionPanel>
                        <Text as="h3" block>About</Text>
                        <Link as="a" className={styles.link} href="">Our Story</Link>
                        <Link as="a" className={styles.link} href="">Partners</Link>
                        <Link as="a" className={styles.link} href="">Job Listing</Link>
                        <Link as="a" className={styles.link} href="">Sustainability</Link>

                        <Text as="h3" block>Portfolio</Text>
                        <Link as="a" className={styles.link} href="">Case Studies</Link>
                        <Link as="a" className={styles.link} href="">Testimonials</Link>
                        <Link as="a" className={styles.link} href="">Awards</Link>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="2">
                    <AccordionHeader as="div" expandIconPosition="end" className={styles.accordionHeader}>
                        <Button appearance="subtle" className={styles.button}>
                            Services
                        </Button>
                    </AccordionHeader>
                    <AccordionPanel>
                        <Text as="h3" block>Microsoft</Text>
                        <Link as="a" className={styles.link} href="">SharePoint</Link>
                        <Link as="a" className={styles.link} href="">Dynamics 365</Link>
                        <Link as="a" className={styles.link} href="">Microsoft 365</Link>
                        <Link as="a" className={styles.link} href="">Teams</Link>

                        <Text as="h3" block>Solutions</Text>
                        <Link as="a" className={styles.link} href="">Process Management</Link>
                        <Link as="a" className={styles.link} href="">Document Management</Link>
                        <Link as="a" className={styles.link} href="">Helpdesk and Support</Link>
                        <Link as="a" className={styles.link} href="">CRM</Link>

                        <Text as="h3" block>Power Platform</Text>
                        <Link as="a" className={styles.link} href="">Power BI</Link>
                        <Link as="a" className={styles.link} href="">Power Automate</Link>
                        <Link as="a" className={styles.link} href="">PowerApps</Link>

                        <Text as="h3" block>Custom Software</Text>
                        <Link as="a" className={styles.link} href="">.NET Development</Link>
                        <Link as="a" className={styles.link} href="">Mobile App Development</Link>

                        <Text as="h3" block>Cloud</Text>
                        <Link as="a" className={styles.link} href="">Azure Hosting</Link>
                        <Link as="a" className={styles.link} href="">Azure Support</Link>
                        <Link as="a" className={styles.link} href="">Cloud Hosting</Link>

                        <Text as="h3" block>Partnerships</Text>
                        <Link as="a" className={styles.link} href="">Mozzaik365</Link>
                        <Link as="a" className={styles.link} href="">Dell Hardware</Link>
                        <Link as="a" className={styles.link} href="">Cyber Essentials</Link>
                        <Link as="a" className={styles.link} href="">Penetration Testing</Link>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Button as="a" appearance="subtle" className={styles.button}>
                Blog
            </Button>
            <Button as="a" appearance="subtle" className={styles.button}>
                Contact
            </Button>
        </>
    );
};