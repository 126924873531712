import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Textarea, Field, Checkbox } from '@fluentui/react-components';
import ITestimonial from '../../models/ITestimonial';
import testimonialService from '../../services/TestimonialService';
import imageService from '../../services/ImageService';
import styles from './EditTestimonial.module.scss';
import IImage from '../../models/IImage';
import IClient from '../../models/IClient';
import IContact from '../../models/IContact';

const initialTestimonial: ITestimonial = {
    id: '',
    client: { name: '', logo: { url: '', altText: '' } as IImage, industry: '', website: '', size: '' } as IClient,
    contact: { name: '', position: '' } as IContact,
    content: '',
    date: '',
    isApproved: false,
    portfolioId: ''
};

export default function EditTestimonial() {
    const { id } = useParams<{ id: string }>();
    const [testimonial, setTestimonial] = useState<ITestimonial>(initialTestimonial);
    const [logoPreview, setLogoPreview] = useState<string | null>(null);  // State for the logo preview

    useEffect(() => {
        if (id) {
            testimonialService.getTestimonial(id).then(data => {
                setTestimonial(data);
            });
        }
    }, [id]);

    useEffect(() => {
        // If testimonial already has a logo URL, set the logoPreview state
        if (testimonial.client.logo.url) {
            setLogoPreview(testimonial.client.logo.url);
        }
    }, [testimonial.client.logo.url]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setTestimonial((prevTestimonial) => (name.startsWith('client') ? {
            ...prevTestimonial,
            client: {
                ...prevTestimonial.client,
                [name.replace('client', '').toLowerCase()]: value,
            },
        } : name.startsWith('contact') ? {
            ...prevTestimonial,
            contact: {
                ...prevTestimonial.contact,
                [name.replace('contact', '').toLowerCase()]: value,
            },
        } : {
            ...prevTestimonial,
            [name]: value,
        }));
    };

    // Inside your component
    const logoInputRef = useRef<HTMLInputElement>(null);

    const handleLogoButtonClick = () => {
        logoInputRef.current?.click();
    };

    const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            try {
                // Upload the logo image
                const logoUploadRequest = {
                    containerName: 'testimonial-uploads',
                    blobName: file.name,
                    url: URL.createObjectURL(file),
                };
                //const logoUrl = await imageService.uploadImage(logoUploadRequest);
    
                // Update the testimonial state
                setTestimonial((prevTestimonial) => ({
                    ...prevTestimonial,
                    client: {
                        ...prevTestimonial.client,
                        //logo: { url: logoUrl, altText: prevTestimonial.client.name },
                    },
                }));

                //setLogoPreview(logoUrl);
    
                console.log('Logo uploaded successfully');
            } catch (error) {
                console.error('Error uploading logo:', error);
            }
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (id) {
            testimonialService.updateTestimonial(id, testimonial).then(() => {
                console.log('Testimonial updated successfully');
            });
        } else {
            testimonialService.createTestimonial(testimonial).then(() => {
                console.log('Testimonial created successfully');
            });
        }
    };

    return (
        <div className={styles.formContainer}>
            <h1>{id ? 'Edit Testimonial' : 'Add New Testimonial'}</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
                <Field label="Client Name" required>
                    <Input name="clientName" value={testimonial.client.name} onChange={handleChange} />
                </Field>
                <Field label="Client Website" required>
                    <Input name="clientWebsite" value={testimonial.client.website} onChange={handleChange} />
                </Field>
                <Field label="Client Industry" required>
                    <Input name="clientIndustry" value={testimonial.client.industry} onChange={handleChange} />
                </Field>
                <Field label="Client Organisation Size" required>
                    <Input name="clientSize" value={testimonial.client.size} onChange={handleChange} />
                </Field>
                <Field label="Contact Name" required>
                    <Input name="contactName" value={testimonial.contact.name} onChange={handleChange} />
                </Field>
                <Field label="Contact Position" required>
                    <Input name="contactPosition" value={testimonial.contact.position} onChange={handleChange} />
                </Field>
                <Field label="Content" required>
                    <Textarea name="content" value={testimonial.content} onChange={handleChange} />
                </Field>
                <Field label="Date" required>
                    <Input
                        type="date"
                        name="date"
                        value={testimonial.date}
                        onChange={handleChange}
                    />
                </Field>
                <Field label="Approved" required>
                    <Checkbox
                        name="isApproved"
                        checked={testimonial.isApproved}
                        onChange={(e, data) => setTestimonial({ ...testimonial, isApproved: data.checked as boolean })}
                    />
                </Field>
                <Field label="Upload Logo">
                    <Button appearance="outline" onClick={handleLogoButtonClick}>
                        Upload Logo
                    </Button>
                    <input
                        type="file"
                        ref={logoInputRef}
                        style={{ display: 'none' }}
                        onChange={handleLogoUpload}
                    />
                </Field>
                {logoPreview && (
                    <div className={styles.logoPreview}>
                        <img src={logoPreview} alt={testimonial.client.logo.url} width={150} height={150} />
                    </div>
                )}
                <Button type="submit" appearance="primary" className={styles.button}>
                    Save
                </Button>
            </form>
        </div>
    );
}