import { Route, Routes, useLocation } from "react-router-dom";
import Navigation from "../components/layout/general/navigation/Navigation";
import Home from "../home/Home";
import Blogs from "../administration/blogs/Blogs";
import AdminLayout from "../components/admin/layout/AdminLayout";
import AdminPartners from "../administration/partners/Partners";
import Testimonials from "../administration/testimonials/Testimonials";
import EditBlog from "../administration/blogs/EditBlog";
import EditTestimonial from "../administration/testimonials/EditTestimonial";
import AdminAwards from "../administration/portfolios/awards/Awards";
import EditAward from "../administration/portfolios/awards/EditAward";
import AdminCaseStudies from "../administration/portfolios/casestudies/CaseStudies";
import EditCaseStudy from "../administration/portfolios/casestudies/EditCaseStudy";
import Blog from "../blog/Blog";
import Footer from "../components/layout/general/footer/Footer";
import SharePoint from "../pages/services/microsoft/Sharepoint";
import Webcon from "../pages/services/solutions/Webcon";
import Dynamics from "../pages/services/microsoft/Dynamics";
import Microsoft365 from "../pages/services/microsoft/Microsoft365";
import Teams from "../pages/services/microsoft/Teams";
import DocumentManagement from "../pages/services/solutions/DocumentManagement";
import ProcessManagement from "../pages/services/solutions/ProcessManagement";
import AzureHosting from "../pages/services/cloud/AzureHosting";
import AzureSupport from "../pages/services/cloud/AzureSupport";
import CloudHosting from "../pages/services/cloud/CloudHosting";
import PowerApps from "../pages/services/powerPlatform/PowerApps";
import PowerAutomate from "../pages/services/powerPlatform/PowerAutomate";
import PowerBI from "../pages/services/powerPlatform/PowerBI";
import CaseStudy from "../components/layout/portfolio/CaseStudy";
import Awards from "../components/layout/portfolio/Awards";
import CaseStudies from "../components/layout/portfolio/CaseStudies";
import Partners from "../components/layout/about/Partners";

const Router = () => {
    const location = useLocation();
    const isAdminRoute = location.pathname.startsWith('/administration');

    return (
        <div className="app-container">
            {!isAdminRoute && <Navigation />}
            <main>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/administration/*" element={<AdminLayout />}>
                        <Route path="blogs" element={<Blogs />} />
                        <Route path="editblog/:id" element={<EditBlog />} />
                        <Route path="editblog" element={<EditBlog />} /> {/* For creating new blogs */}
                        <Route path="casestudies" element={<AdminCaseStudies />} />
                        <Route path="editcasestudy/:id" element={<EditCaseStudy />} />
                        <Route path="editcasestudy" element={<EditCaseStudy />} /> {/* For creating new portfolios */}
                        <Route path="partners" element={<AdminPartners />} />
                        <Route path="testimonials" element={<Testimonials />} />
                        <Route path="edittestimonial/:id" element={<EditTestimonial />} />
                        <Route path="edittestimonial" element={<EditTestimonial />} /> {/* For creating new testimonials */}
                        <Route path="awards" element={<AdminAwards />} />
                        <Route path="editaward/:id" element={<EditAward />} />
                        <Route path="editaward" element={<EditAward />} /> {/* For creating new testimonials */}
                    </Route>
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/casestudies" element={<CaseStudies />} />
                    <Route path="/casestudy/:id" element={<CaseStudy />} />
                    <Route path="/awards" element={<Awards />} />
                    <Route path="/services/sharepoint" element={<SharePoint />} />
                    <Route path="/services/webcon" element={<Webcon />} />
                    <Route path="/services/dynamics" element={<Dynamics />} />
                    <Route path="/services/microsoft365" element={<Microsoft365 />} />
                    <Route path="/services/teams" element={<Teams />} />
                    <Route path="/services/documentmanagement" element={<DocumentManagement />} />
                    <Route path="/services/processmanagement" element={<ProcessManagement />} />
                    <Route path="/services/azurehosting" element={<AzureHosting />} />
                    <Route path="/services/azuresupport" element={<AzureSupport />} />
                    <Route path="/services/cloudhosting" element={<CloudHosting />} />
                    <Route path="/services/powerapps" element={<PowerApps />} />
                    <Route path="/services/powerautomate" element={<PowerAutomate />} />
                    <Route path="/services/powerbi" element={<PowerBI />} />
                    <Route path="/partners" element={<Partners />} />
                </Routes>
            </main>
            {!isAdminRoute && <Footer />}
        </div>
    );
};

export default Router;