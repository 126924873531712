import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Textarea, Field, Combobox, Option, ComboboxProps } from '@fluentui/react-components';
import ICaseStudy from '../../../models/ICaseStudy';
import portfolioService from '../../../services/PortfolioService';
import imageService from '../../../services/ImageService';
import styles from './EditCaseStudy.module.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import IImage from '../../../models/IImage';
import IClient from '../../../models/IClient';

const initialCaseStudy: ICaseStudy = {
    id: '',
    title: '',
    subtitle: '',
    image: { url: '', altText: '' } as IImage,
    thumbnail: { url: '', altText: '' } as IImage,
    client: { name: '', website: '', size: '', logo: { url: '', altText: '' } as IImage, } as IClient,
    introduction: '',
    brief: '',
    solution: '',
    slug: '',
    metaDescription: '',
    tags: []
};

export default function EditCaseStudy() {
    const { id } = useParams<{ id: string }>();
    const [caseStudy, setCaseStudy] = useState<ICaseStudy>(initialCaseStudy);
    const [, setSelectedTags] = useState<string[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [thumbnailPreview, setThumbnailPreview] = useState<string | null>(null);  // State for the thumbnail preview
    const [logoPreview, setLogoPreview] = useState<string | null>(null);  // State for the logo preview

    useEffect(() => {
        portfolioService.getCategories().then(setCategories);
        if (id) {
            portfolioService.getCaseStudy(id).then((data) => {
                setCaseStudy(data);
                setSelectedTags(data.tags); // Sync tags with the fetched data
            });
        }
    }, [id]);

    useEffect(() => {
        // If case study already has a thumbnail URL, set the thumbnailPreview state
        if (caseStudy.thumbnail.url) {
            setThumbnailPreview(caseStudy.thumbnail.url);
        }
        // If case study already has a logo URL, set the logoPreview state
        if (caseStudy.client.logo.url) {
            setLogoPreview(caseStudy.client.logo.url);
        }
    }, [caseStudy.thumbnail.url, caseStudy.client.logo.url]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setCaseStudy((prevCaseStudy) => (name.startsWith('client') ? {
            ...prevCaseStudy,
            client: {
                ...prevCaseStudy.client,
                [name.replace('client', '').toLowerCase()]: value,
            },
        } : {
            ...prevCaseStudy,
            [name]: value,
        }));
    };

    const handleBodyChange = (value: string) => {
        setCaseStudy((prevCaseStudy) => ({ ...prevCaseStudy, brief: value }));
    };

    const onSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
        setSelectedTags(data.selectedOptions);
        setCaseStudy((prevCaseStudy) => ({ ...prevCaseStudy, tags: data.selectedOptions }));
    };

    // Inside your component
    const fileInputRef = useRef<HTMLInputElement>(null);
    const logoInputRef = useRef<HTMLInputElement>(null);

    const handleFileButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleLogoButtonClick = () => {
        logoInputRef.current?.click();
    };

    const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            try {
                // Generate a thumbnail
                const createThumbnail = (imageFile: File): Promise<File> => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = URL.createObjectURL(imageFile);
                        img.onload = () => {
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            if (!ctx) {
                                reject(new Error("Canvas context not available"));
                                return;
                            }
    
                            // Set the dimensions for the thumbnail
                            const thumbnailWidth = 150; // Adjust as needed
                            const thumbnailHeight = 150; // Adjust as needed
    
                            canvas.width = thumbnailWidth;
                            canvas.height = thumbnailHeight;
    
                            // Draw the resized image on the canvas
                            ctx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);
    
                            // Convert canvas to blob
                            canvas.toBlob(
                                (blob) => {
                                    if (!blob) {
                                        reject(new Error("Blob conversion failed"));
                                        return;
                                    }
                                    const thumbnailFile = new File([blob], `thumbnail-${file.name}`, {
                                        type: file.type,
                                    });
                                    resolve(thumbnailFile);
                                },
                                file.type,
                                0.9 // Adjust quality as needed
                            );
                        };
                        img.onerror = (err) => reject(err);
                    });
                };
    
                const thumbnailFile = await createThumbnail(file);
    
                // Upload the original image
                const originalImageUploadRequest = {
                    containerName: 'portfolio-uploads',
                    blobName: file.name,
                    url: URL.createObjectURL(file),
                };
                //const originalImageUrl = await imageService.uploadImage(originalImageUploadRequest);
    
                // Upload the thumbnail image
                const thumbnailUploadRequest = {
                    containerName: 'portfolio-uploads',
                    blobName: thumbnailFile.name,
                    url: URL.createObjectURL(thumbnailFile),
                };
                //const thumbnailUrl = await imageService.uploadImage(thumbnailUploadRequest);
    
                // Update the case study state
                setCaseStudy((prevCaseStudy) => ({
                    ...prevCaseStudy,
                    //image: { url: originalImageUrl, altText: prevCaseStudy.title },
                    //thumbnail: { url: thumbnailUrl, altText: prevCaseStudy.title },
                }));

                //setThumbnailPreview(thumbnailUrl);
    
                console.log('Images uploaded successfully');
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            try {
                // Upload the logo image
                const logoUploadRequest = {
                    containerName: 'portfolio-uploads',
                    blobName: file.name,
                    url: URL.createObjectURL(file),
                };
                //const logoUrl = await imageService.uploadImage(logoUploadRequest);
    
                // Update the case study state
                setCaseStudy((prevCaseStudy) => ({
                    ...prevCaseStudy,
                    client: {
                        ...prevCaseStudy.client,
                        //logo: { url: logoUrl, altText: prevCaseStudy.title },
                    },
                }));

                //setLogoPreview(logoUrl);
    
                console.log('Logo uploaded successfully');
            } catch (error) {
                console.error('Error uploading logo:', error);
            }
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (id) {
            portfolioService.updateCaseStudy(id, caseStudy).then(() => {
                console.log('Case study updated successfully');
            });
        } else {
            portfolioService.createCaseStudy(caseStudy).then(() => {
                console.log('Case study created successfully');
            });
        }
    };

    return (
        <div className={styles.formContainer}>
            <h1>{id ? 'Edit Case Study' : 'Add New Case Study'}</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
                <Field label="Title" required>
                    <Input name="title" value={caseStudy.title} onChange={handleChange} />
                </Field>
                <Field label="Subtitle">
                    <Input name="subtitle" value={caseStudy.subtitle || ''} onChange={handleChange} />
                </Field>
                <Field label="Client Name" required>
                    <Input name="clientName" value={caseStudy.client.name} onChange={handleChange} />
                </Field>
                <Field label="Client Website" required>
                    <Input name="clientWeb" value={caseStudy.client.website} onChange={handleChange} />
                </Field>
                <Field label="Client Organisation Size" required>
                    <Input name="clientSize" value={caseStudy.client.size} onChange={handleChange} />
                </Field>
                <Field label="Introduction" required>
                    <ReactQuill
                        theme="snow"
                        value={caseStudy.introduction}
                        onChange={handleBodyChange}
                        style={{ height: '150px', marginBottom: '40px' }}
                    />
                </Field>
                <Field label="Brief" required>
                    <ReactQuill
                        theme="snow"
                        value={caseStudy.brief}
                        onChange={handleBodyChange}
                        style={{ height: '150px', marginBottom: '40px' }}
                    />
                </Field>
                <Field label="Solution" required>
                    <ReactQuill
                        theme="snow"
                        value={caseStudy.solution}
                        onChange={handleBodyChange}
                        style={{ height: '150px', marginBottom: '40px' }}
                    />
                </Field>
                <Field label="Upload Image">
                    <Button appearance="outline" onClick={handleFileButtonClick}>
                        Upload Image
                    </Button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleImageUpload}
                    />
                </Field>
                {thumbnailPreview && (
                    <div className={styles.thumbnailPreview}>
                        <img src={thumbnailPreview} alt={caseStudy.thumbnail.url} width={150} height={150} />
                    </div>
                )}
                
                <div className={styles.inlineFields}>
                    <Field label="Slug" required>
                        <Input name="slug" value={caseStudy.slug} onChange={handleChange} />
                    </Field>
                    <Field label="Upload Logo">
                    <Button appearance="outline" onClick={handleLogoButtonClick}>
                        Upload Logo
                    </Button>
                    <input
                        type="file"
                        ref={logoInputRef}
                        style={{ display: 'none' }}
                        onChange={handleLogoUpload}
                    />
                </Field>
                {logoPreview && (
                    <div className={styles.logoPreview}>
                        <img src={logoPreview} alt={caseStudy.client.logo.url} width={150} height={150} />
                    </div>
                )}
                </div>
                <Field label="Meta Description" required>
                        <Textarea name="metaDescription" value={caseStudy.metaDescription} onChange={handleChange} />
                    </Field>
                    <Field label="Categories" required>
                    <Combobox
                        multiselect={true}
                        placeholder="Select one or more categories"
                        value={caseStudy.tags.join(", ")} // Display selected tags as comma-separated
                        onBlur={() => {
                            // Ensure selected tags remain displayed on blur
                            const displayValue = caseStudy.tags.join(", ");
                            setCaseStudy((prevCaseStudy) => ({ ...prevCaseStudy, displayTags: displayValue }));
                        }}
                        onOptionSelect={onSelect}
                    >
                        {categories.map((category) => (
                            <Option key={category} value={category}>
                                {category}
                            </Option>
                        ))}
                    </Combobox>
                </Field>
                <Button type="submit" appearance="primary" className={styles.button}>
                    Save
                </Button>
            </form>
        </div>
    );
}