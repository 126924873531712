import { useEffect, useState } from "react";
import { DialogSurface, makeStyles } from "@fluentui/react-components";
import { Image, Text, Dialog, DialogTrigger, DialogContent, DialogBody, Button } from "@fluentui/react-components";
import portfolioService from "../../../services/PortfolioService";
import IAward from "../../../models/IAward";

const useStyles = makeStyles({
    mainContainer: {
        padding: 0,
    },
    headerImageContainer: {
        position: "relative",
        width: "100%",
        height: "600px",
    },
    headerImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        filter: "brightness(0.7)",
    },
    headerTextOverlay: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "white",
        textAlign: "center",
    },
    gallery: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))", // Ensure 3 items per row
        gap: "20px",
        maxWidth: "1200px",
        margin: "2rem auto", // Center the gallery
    },
    galleryItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start", // Align items to the top
        overflow: "hidden",
        height: "500px", // Set a fixed height for consistency
        margin: "10px",
        position: "relative",
    },
    galleryImageContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80%", // Adjust height to leave space for the title
        width: "100%",
        overflow: "hidden",
        position: "relative",
    },
    galleryImage: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "cover", // Ensure the image fits within the container
        position: "relative",
    },
    galleryDescription: {
        marginTop: "10px",
        textAlign: "center",
        height: "20%", // Adjust height to fit within the container
    },
    modalImage: {
        width: "100%",
        height: "auto",
        objectFit: "contain",
    },
    modalCaption: {
        marginTop: "10px",
        textAlign: "center",
        fontSize: "1.2rem",
    },
    carouselButtonContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "10px",
    },
    carouselButton: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        color: "white",
        border: "none",
        cursor: "pointer",
        padding: "10px",
    },
});

const hardcodedAwards: IAward[] = [
    {
        image: { url: "https://dolphinwebsiteproduction.blob.core.windows.net/award-uploads/award-Surrey%202023%20-%20Bronze%20Winner.jpg", altText: "Award 1" },
        description: "Award 1 Description",
        id: "1",
        name: "Best Design Award",
    },
    {
        image: { url: "https://dolphinwebsiteproduction.blob.core.windows.net/award-uploads/award-ISO.png", altText: "Award 2" },
        description: "Award 2 Description",
        id: "2",
        name: "Innovation Award",
    },
    {
        image: { url: "https://dolphinwebsiteproduction.blob.core.windows.net/award-uploads/award-cybersecurity%20plus.png", altText: "Award 3" },
        description: "Award 3 Description",
        id: "3",
        name: "Community Choice Award",
    },
];

export default function Awards() {
    const styles = useStyles();
    const [awards, setAwards] = useState<IAward[]>(hardcodedAwards);
    const [selectedAward, setSelectedAward] = useState<IAward | null>(null);

    useEffect(() => {
        const fetchAwards = async () => {
            try {
                const awardsData = await portfolioService.getAwards();
                setAwards(awardsData || hardcodedAwards);
            } catch (error) {
                console.error("Failed to fetch awards:", error);
            }
        };

        fetchAwards();
    }, []);

    const openAwardModal = (award: IAward) => {
        setSelectedAward(award);
    };

    const closeAwardModal = () => {
        setSelectedAward(null);
    };

    const showNextAward = () => {
        if (selectedAward) {
            const currentIndex = awards.findIndex(award => award.id === selectedAward.id);
            const nextIndex = (currentIndex + 1) % awards.length;
            setSelectedAward(awards[nextIndex]);
        }
    };

    const showPrevAward = () => {
        if (selectedAward) {
            const currentIndex = awards.findIndex(award => award.id === selectedAward.id);
            const prevIndex = (currentIndex - 1 + awards.length) % awards.length;
            setSelectedAward(awards[prevIndex]);
        }
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.headerImageContainer}>
                <Image
                    src="https://www.consultdolphin.com/images/pages/awards/banner.webp"
                    alt="Header Image"
                    className={styles.headerImage}
                />
                <div className={styles.headerTextOverlay}>
                    <Text as="h1" size={900} align="center" block>
                        Our Awards
                    </Text>
                    <Text as="h3" size={600} align="center" block>
                        Over the years we"ve acquired a number of certificates and awards.
                    </Text>
                </div>
            </div>

            <div className={styles.gallery}>
                {awards.map((award, index) => (
                    <div
                        key={index}
                        className={styles.galleryItem}
                        onClick={() => openAwardModal(award)}
                    >
                        <div className={styles.galleryImageContainer}>
                            <Image src={award.image.url} alt={award.image.altText} className={styles.galleryImage} />
                        </div>
                        <Text className={styles.galleryDescription}>{award.name}</Text>
                    </div>
                ))}
            </div>

            {selectedAward && (
                <Dialog open={!!selectedAward} onOpenChange={closeAwardModal}>
                    <DialogSurface>
                        <DialogTrigger></DialogTrigger>
                        <DialogBody>
                            <DialogContent>
                                <Image
                                    src={selectedAward.image.url}
                                    alt={selectedAward.image.altText}
                                    className={styles.modalImage}
                                />
                                <Text className={styles.modalCaption}>
                                    <strong>{selectedAward.name}</strong>: {selectedAward.description}
                                </Text>
                                <div className={styles.carouselButtonContainer}>
                                    <Button className={styles.carouselButton} onClick={showPrevAward}>
                                        &lt;
                                    </Button>
                                    <Button className={styles.carouselButton} onClick={showNextAward}>
                                        &gt;
                                    </Button>
                                </div>
                            </DialogContent>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            )}
        </div>
    );
}