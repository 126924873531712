import React from "react";
import { Card, Image, Text } from "@fluentui/react-components";
import { Col, Container, Row } from "react-grid-system";
import styles from "./ServiceCards2.module.scss";

interface CardItem {
  title: string;
  description: string;
  imageSrc: string;
  imageAlt: string;
  link?: string;
}

interface FeatureSectionProps {
  title: string;
  subtitle: string;
  cards: CardItem[];
}

const ServiceCards2: React.FC<FeatureSectionProps> = ({
  title,
  subtitle,
  cards,
}) => {
  return (
    <Container fluid className={styles.mainContainer}>
      {/* Header Section */}
      <Row className={styles.row} justify="center">
        <Col md={12}>
          <Text as="h2" size={800} align="center" block>
            {title}
          </Text>
          <Text as="h4" size={500} style={{ color: "#869ab8" }} align="center" block>
            {subtitle}
          </Text>
        </Col>
      </Row>

      <Row className={styles.row}>
        {cards.map((card, index) => (
          <Col md={6} style={{ marginBottom: "1rem" }} key={index}>
            <Card className={styles.cardContainer}>
              <div className={styles.cardContent}>
                <div className={styles.cardImageContainer}>
                  <Image
                    src={card.imageSrc}
                    alt={card.imageAlt}
                    className={styles.cardImage}
                  />
                </div>
                <div className={styles.cardTextContainer}>
                    <Text as="h4" size={500} block align="center">
                        {card.title}
                    </Text>
                    <Text as="p" size={400} block style={{ color: "#869ab8" }}>
                        {card.description}
                    </Text>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ServiceCards2;