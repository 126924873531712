import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, shorthands, Image, Text, Card, CardHeader } from '@fluentui/react-components';
import ICaseStudy from '../../../models/ICaseStudy';
import portfolioService from '../../../services/PortfolioService';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...shorthands.padding('20px'),
        gap: '20px',
    },
    headerImageContainer: {
        position: "relative",
        width: "100%",
        height: "600px",
    },
    headerImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    headerTextOverlay: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "white",
        textAlign: "center",
    },
    titleOverlay: {
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '10px 15px',
        borderRadius: '8px',
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    section: {
        width: '100%',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        ...shorthands.padding('20px'),
        backgroundColor: '#f3f2f1',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    field: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    label: {
        fontWeight: 'bold',
    },
});

const CaseStudy: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [caseStudy, setCaseStudy] = useState<ICaseStudy | null>(null);
    const styles = useStyles();

    useEffect(() => {
        const fetchCaseStudy = async () => {
            try {
                const data = await portfolioService.getCaseStudy(id!);
                setCaseStudy(data);
            } catch (error) {
                console.error('Error fetching case study:', error);
            }
        };

        fetchCaseStudy();
    }, [id]);

    if (!caseStudy) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.headerImageContainer}>
                <Image
                    src="https://www.consultdolphin.com/assets/img/covers/cover-3.jpg"
                    alt="Header Image"
                    className={styles.headerImage}
                />
                <div className={styles.headerTextOverlay}>
                    <Text as="h1" size={900} align="center" block>
                        {caseStudy.title}
                    </Text>
                    <Text as="h3" size={600} align="center" block>
                        {caseStudy.subtitle}
                    </Text>
                </div>
            </div>

            <Card className={styles.section}>
                <CardHeader>
                    <Text as="h2" weight="semibold">
                        Overview
                    </Text>
                </CardHeader>
                    <div className={styles.field}>
                        <Text className={styles.label} block>Client</Text>
                        <Text>{caseStudy.client.name}</Text>
                    </div>
                    <div className={styles.field}>
                        <Text className={styles.label} block>Introduction</Text>
                        <Text>{caseStudy.introduction}</Text>
                    </div>
            </Card>
            <Card className={styles.section}>
                <CardHeader>
                    <Text as="h2" weight="semibold">
                        Details
                    </Text>
                </CardHeader>
                    <div className={styles.field}>
                        <Text className={styles.label} block>Brief</Text>
                        <Text>{caseStudy.brief}</Text>
                    </div>
                    <div className={styles.field}>
                        <Text className={styles.label} block>Solution</Text>
                        <Text>{caseStudy.solution}</Text>
                    </div>
            </Card>
        </div>
    );
};

export default CaseStudy;
